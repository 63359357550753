<template>
    <a-modal :visible="show" @cancel="$emit('cancel')" @ok="save" :title="title || 'Edit Content'">
        <div>
            <h4>Content</h4>
            <div>
                <RichText v-model="editContent" :format="true" />
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props:['show','content','title'],
    components:{
        RichText: () => import('@/components/common/RichText'),
    },
    data:( () => ({
        editContent:'',
    })),
    methods:{
        save(){
            this.$emit('save',this.editContent)
        }
    },
    created(){
        this.editContent = this.content
    },
}
</script>

<style>

</style>