<template>
    <div class="px-2">
        <div v-if="blocksData && blocksData.length">
            <template v-for="(block,blockI) in blocksData">

                <a-collapse expandIconPosition="right" :key="blockI" class="mb-2">

                    <a-collapse-panel key="1" :header="blocksObj[block.id].name" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;'">

                        <template v-for="(settingItem,settingItemI) in settings[block.id]">

                                <a-form-item :label="settingItem.label ? settingItem.label :'Unknown'" :key="`section-${settingItem.id}-item-${settingItemI}${settingItem.id}`" :help="showHelp(settingItem,block.data[settingItem.id])">
                                    <SectionEditItem :font-list="fontList" :key="`block-${blockI}-${block.id}-setting-${settingItemI}`" :data="block.data" :setting="settingItem"/>
                                </a-form-item>

                        </template>
                        <a-popconfirm
                            title="Are you sure delete this block?"
                            ok-text="Yes"
                            cancel-text="No"
                            @confirm="removeBlock(blockI)"
                        >
                            <a href="#"  class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc; border-color:var(--danger); color:var(--danger)">
                                <a-icon type="minus-circle" /> Remove Block
                            </a>
                        </a-popconfirm>
                        

                    </a-collapse-panel>

                </a-collapse>
                    
            </template>
        </div>
        <div v-else class="empty-blocks p-3" style="color:#ccc; font-size:14px; text-align:center;">
            No Blocks Added
        </div>
        <template v-if="!limitReached">
            <div @click="addBlock" v-if="blocks.length === 1" class="p-3 dF jC aC cursor-pointer no-selection" style="gap:1ch; border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
                <a-icon type="plus-circle" />Add {{columnName}}
            </div>
            <a-dropdown :trigger="['click']" v-else>
                <div  class="p-3 dF jC aC cursor-pointer no-selection" style="gap:1ch; border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
                    <a-icon type="plus-circle" />Add Block
                </div>
                <a-menu slot="overlay" @click="onBlockChosen">
                    <a-menu-item v-for="blk in blocks" :key="blk.id">
                        {{blk.name}}
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </template>
        <div v-else class="p-3 dF jC aC fC cursor-pointer no-selection text-center" style="border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
            
            <div class="dF aC" style="gap:1ch;">
                <a-icon type="stop" /> Block limit reached
            </div> 
            <span style="color:#aaa">Please remove a block to add.</span>
        </div>
    </div>
</template>

<script>
export default {
    components:{SectionEditItem: () => import('@/components/site/SectionEditItem')},
    props:['item','font-list'],
    computed:{
        limitReached(){
            return this.limit !== 0 && this.blocksData.length >= this.limit
        },
        limit(){
            return this.item.blocks.limit || 0
        },
        blocksObj(){
            let blocks = {} 
            this.blocks.forEach( blk => {
                blocks[blk.id] = blk
            })
            return blocks
        },
        settings(){
            let settings = {} 
            this.blocks.forEach( blk => {
                settings[blk.id] = blk.settings
            })
            return settings
        },
        blocks(){
            return this.item.blocks.blocks
        },
        columnName(){
            return this.blocks.length === 1? this.blocks[0].name : 'Element'
        },
        blocksData(){
            return this.item.blocks.blocksData || []
        },
        selectBlocks(){
            if (Array.isArray(this.item.blocks)) return this.item.blocks
            else if (this.item.blocks.settings) return [this.item.blocks]
        },
       
    },
    methods:{
        addBlock(id){
            console.log('id from add block', id)
            console.log('THIS ITEM BLOCKS BLOCKSDATA', this.item)
            if (typeof id !== 'string') id = this.blocks[0].id
            let newBlock = {
                id,
                data:{}
            }
            let settings = this.settings[id]
            settings.forEach(x => {
                newBlock.data[x.id] = JSON.parse(JSON.stringify(x.default))
            })
            if (this.limit !== 0 && this.blocksData.length >= this.limit){
                console.error('Limit reached for blocks')
            } else {
                console.log('else from add block ran')
                this.item.blocks.blocksData.push(newBlock)
            }
        },
        removeBlock(i){
            this.blocksData.splice(i,1)
        },
        onBlockChosen(e){
            console.log('MENU CLICK', e)
           this.addBlock(e.key)

        },
        showHelp(item,val){
            if (!val) return item.help || ''
            if (item.type === 'linkText') {
                if (val.indexOf('http') !== 0){
                    return 'Note: your link does not seem valid'
                } else if (!item.help){
                    return 'Enter a valid Link'
                }
            }
            if (item.help) return item.help
            return ''
        },
    },
    created(){
        console.log('THISSSSS BLOCKS', this.blocks)
        console.log('THISSSS ITEM BLOCKS', this.item.blocks)
        if (!this.blocks) this.item.blocks.blocksData = []
    },
}
</script>

<style>

</style>