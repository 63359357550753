<template>
  <div style="border-left:1px solid #e4e4ea">
      <div  id="fieldOptions" style="overflow: hidden;background:#f1f1f1" class="h-full bg-offwhite-light dF" :style="`${hide? 'width:0px' : ''}`" >

            <SectionModal :project="project" />

            <div class="dF fC right-tool-bar" :class="rightBarSelected? `right-tool-open` : ``" >

              <div class="f1 dF fC relative" style="overflow:hidden;">

                <div class="f1 hide-scrollbar" style="overflow-x:hidden; overflow-y:scroll;">
                    <template v-if="rightBarSelected === 'pages'">
                        <ManagePages @sections="rightBarSelected = 'sections'" :event="event" :project="project" :page="page" />
                    </template>

                    <template v-if="rightBarSelected === 'sections'">
                        <ManageSections :event="event" :project="project" :page="page" />
                    </template>

                    <template v-if="rightBarSelected === 'blogs'">
                        <BlogSettings :project="project" />
                    </template>

                    <template v-else-if="rightBarSelected === 'settings'">
                        <ProjectSettings :project="project" />
                    </template>

                    <template v-else-if="rightBarSelected === 'edit'">
                      <div class="field-options-footer" :class="Boolean(selectedField) ? '' : 'dF jC aC muted-colors mt-4'">
                        <div class="text-center" v-if="!Boolean(selectedField)">
                          <a-icon type="gateway" />
                          <small class="mt-3 block">Please select a Field to edit</small>
                        </div>
                      </div>
                    </template>

                </div>

              </div>

            </div>
            <div class="rightBarToggle cursor-pointer">
                <a-tooltip v-if="$p > 10" overlayClassName="change-tooltip-color" title="Pages" placement="left">
                    <div class="right-toolbar-item py-3" :class="rightBarSelected === 'pages'? 'active' : ''" @click="rightBarSelected = 'pages'">
                        <i class="fa fa-file"></i>
                    </div>
                </a-tooltip>
                <a-tooltip v-if="$p > 10" overlayClassName="change-tooltip-color" title="Page Sections" placement="left">
                    <div class="right-toolbar-item py-3" :class="rightBarSelected === 'sections'? 'active' : ''" @click="rightBarSelected = 'sections'">
                        <i class="fa fa-list-ul"></i>
                    </div>
                </a-tooltip>
				<a-tooltip overlayClassName="change-tooltip-color" title="Blog Posts" placement="left" v-if="project.type === 'microsite' ">
                    <div class="right-toolbar-item py-3" :class="rightBarSelected === 'blogs'? 'active' : ''" @click="rightBarSelected = 'blogs'">
                        <i class="fa fa-edit"></i>
                    </div>
                </a-tooltip>
                <a-tooltip v-if="$p > 10" overlayClassName="change-tooltip-color" title="Project Settings" placement="left">
                    <div class="right-toolbar-item py-3" :class="rightBarSelected === 'settings'? 'active' : ''" @click="rightBarSelected = 'settings'">
                        <i class="fa fa-gear"></i>
                    </div>
                </a-tooltip>
            </div>
          </div>
          <ColorDialog />
  </div>
</template>

<script>
import ProjectSettings from '@/components/site/ProjectSettings'
import ManageSections from '@/components/site/ManageSections'
import ManagePages from '@/components/site/ManagePages'
import SectionModal from '@/components/site/SectionModal'
import ColorDialog from '@/components/site/ColorDialog'
import BlogSettings from '@/components/site/BlogSettings'

export default {
    components:{
        ColorDialog,SectionModal,ProjectSettings,ManageSections,ManagePages,BlogSettings
    },
    inject: ['saveCurrentPage'],
    props:{
        project:{
            type:Object,
            default:() => {}
        },
        page:{
            type:Object,
            default:() => {}
        },
        event:{
            type:Object,
            default:() => {}
        },
        selectedSection:{
            type:String,
            default:''
        },
        hide:{
            type:Boolean,
            default:false
        }
    },
    data: () => ({
        editSectionObj:{
            show:false,
            item:{}
        },
        activeSection:'',
        rightBarSelected:'sections',
        currentRightToolBar:{
            sections:'Sections',
            settings:'Project Settings',
        },

    }),
    watch:{
        selectedID(val){

            if (!val) return
            this.rightBarSelected = 'sections'

        },
        event(val){
            if (typeof val === 'object'){
                if (val.action === 'sectionEdit'){
                    this.rightBarSelected = 'sections'
                }
            }
        },
        activeSection(val){
            document.querySelector('iframe').contentWindow.postMessage({action:'selectedWidget',id:val}, this.siteURL)
        },
        selectedSection(val){
            if (val.includes('section-id-')) val = val.replace('section-id-', '')
            this.activeSection = val
        },
        rightBarSelected(val){
			if(this.$p === 10 && val !== "blogs"){
				this.rightBarSelected = "blogs";
			}
            this.$store.commit('SET_PROP', {where:['builder','currentSection'],what:''})
            document.querySelector('iframe').contentWindow.postMessage({action:'unSelect'},this.siteURL)
        }
    },
    methods:{
        addSection(){
            this.$store.commit('SET_PROP',{where:['sectionModal'],what:{show:true,cb:this.addSectionCallBack}})
        },
        showHelp(item,val){
            if (!val) return item.help || ''
            if (item.type === 'linkText') {
                if (val.indexOf('http') !== 0){
                    return 'Note: your link does not seem valid'
                } else if (!item.help){
                    return 'Enter a valid Link'
                }
            }
            if (item.help) return item.help
            return ''
        },
        editSection(item){

            this.editSectionObj = {
                show:true,
                item
            }
            document.querySelector('iframe').contentWindow.postMessage({action:'selectedWidget',id:item.id})

        },
        removeSection(id){
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Section?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk:() => {
                    this.page.sections = this.page.sections.filter(x => x.id !== id)
                    this.editSectionObj = {show:false,item:{}}
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
    },
    computed:{
        selectedID(){
            return this.$store.state.appData.builder.currentSection
        },
        getHeaders(){
            if (this.page.header && this.page.header.id) return [{...this.page.header,tag:'Header'}]
            if (this.project.headers && this.project.headers.length) return this.project.headers.map(x => ({...x,tag:'Header'}))
            return false
        },
        getFooters(){
            if (this.page.footer && this.page.footer.id) return [{...this.page.footer,tag:'Footer'}]
            if (this.project.footers && this.project.footers.length) return this.project.footers.map(x => ({...x,tag:'Footer'}))
            return false
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        siteURL(){
            return this.$store.state.appData.siteURL
        },
        fontList(){
            return Object.values(this.project.fonts)
                .map( ({name,fontText}) => ({
                    label:name,
                    value:fontText
                }))
        },
        sections(){
            return this.page.sections || []
        }
    },
	created() {
		if(this.$p === 10){
			this.rightBarSelected = "blogs";
		}
	}
}
</script>

<style lang="scss">
    .right-tool-bar{
		width:0px;
		overflow:hidden;
        position: relative;
        > div{
            position: absolute;
            left:0;
            top:0;
            right:0;
            bottom:0;
        }
	}

    .right-tool-bar.right-tool-open{
        width: 300px;
        overflow: hidden;
    }
	.right-tool-bar{
        border-right:1px solid var(--light-purple);
    }
    #fieldOptions{
		transition:width .3s ease-out;
		border-left:1px solid var(--light-purple);
	}
    .rightBarToggle{
		width:46px;
		background:white;
		display:flex;
		flex-direction: column;
		cursor:pointer;

		> .right-toolbar-item{
			width:46px;
			display:flex;
			align-items: center;
			justify-content: center;
			&.active{
				background:#f8f8f8;
			}
		}

	}

	.right-tool-bar{
		transition:width .3s ease-out;
	}
    .editor{

    }
    .editor:focus, .editor *:focus{
        outline:none;
    }
    .editor__content{
        padding:10px;
        border:1px solid #eee;
        line-height:1.6;
    }
    .editor-menubar__button{
        all:unset;
    }
    .menubar{
        padding:4px 8px;
        background:var(--light-bg-gray);
        line-height:1.6;
        > button{
            + button{
                margin-left:5px;
            }
        }
    }
</style>
