<template>
    <a-modal :width="900"  :visible="show" okText="Update" @ok="submitRedirects" @cancel="$emit('cancel')" >
        <RedirectTable v-model="redirects.list" />
        <a-tabs default-active-key="301" v-if="false">
            <a-tab-pane key="301" tab="Permanent"> 
                <RedirectTable v-model="redirects" />
            </a-tab-pane>
            <a-tab-pane key="canonical" tab="Canonical"> 
                <RedirectTable v-model="redirects.canonical" />
            </a-tab-pane>

        </a-tabs>
        
    </a-modal>
</template>

<script>
import RedirectTable from './RedirectTable'
export default {
    props:['project','show'],
    data:() => ({
        redirects:{},
    }),
    watch:{
        show(val){
            if(val){
                
                if (!this.project.seo) {
                    this.project.seo = {}
                }
                if (!this.project.seo.redirects) {
                    this.project.seo.redirects = {
                        list:[]
                    }
                }

                this.redirects = JSON.parse(JSON.stringify(this.project.seo.redirects))

            }
        }
    },
    components:{
        RedirectTable
    },
    methods:{
        submitRedirects(){
            this.$emit('cancel')
            this.project.seo.redirects = JSON.parse(JSON.stringify(this.redirects))
            console.log('THIS PROJECTS', this.redirects)
        },
    },
}
</script>

<style>

</style>