<template >
    <a-modal  :visible="show" okText="Update" @ok="submit" @cancel="$emit('close')">
        <a-tabs default-active-key="general">
            <a-tab-pane key="general" tab="General">
                <a-form-model :model="page" v-if="page && page.id">
                    <a-form-model-item label="Page Title" class="mb-1" has-feedback :validate-status="nameError? 'error' : 'success'" :help="nameError? nameDup? 'Name already exist! Please choose another' : 'Name is required' : '' ">
                        <a-input v-model.lazy="page.name"  />
                    </a-form-model-item>
                    <a-form-model-item label="Slug" class="mb-2">
                        <a-input v-if="page.isIndex" :value="'/'" disabled/>
						<a-input v-else :value="pageSlug" disabled/>
                    </a-form-model-item>
                    <!-- <a-divider orientation="left">Header/Footer</a-divider> -->
                    <a-row class="" style="border-bottom:1px solid #ccc">
                        <a-col :span="8">
                            <a-form-model-item label="Show Header">
                                <a-switch v-model="page.isHeader" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-model-item label="Show Footer">
                                <a-switch v-model="page.isFooter" />
                            </a-form-model-item>
                        </a-col>

                    </a-row>
                </a-form-model>
            </a-tab-pane>
            <a-tab-pane v-if="page.slug != 'blogpost' && page.slug != 'modeldetails'" key="seo" tab="SEO">
                 <a-form-model :model="page" v-if="page && page.id">
                    <a-form-model-item label="Page Description" class="mb-3" has-feedback :validate-status="nameError? 'error' : 'success'" :help="nameError? nameDup? 'Name already exist! Please choose another' : 'Name is required' : '' ">
                        <a-textarea v-model.lazy="page.seo.description"  />
                    </a-form-model-item>
                    <a-form-model-item label="Disable SEO Index" >
                        <a-switch v-model="page.seo.noIndex" />
                    </a-form-model-item>
                 </a-form-model>
            </a-tab-pane>

        </a-tabs>

    </a-modal>
</template>

<script>
import MoreActions from 'bh-mod/components/common/MoreActions'
export default {
    inject:['makeSlug','updateFrame','pageGotEdited'],
    props:{
        value:{
            type:Object,
            default:( () => ({}))
        }
    },
    components:{MoreActions},
    data:() => ({
        nameDup:false,
        hellow:false,
        page:{
            seo:{
                noIndex:false
            }
        },
    }),
    watch:{
        'page.name'(val){
            console.log('PAGE NAME CHANGING')
            let madename = this.makeSlug(val,this.page.id)
            if ((madename && madename.name && madename.name.toLowerCase().trim()) !== (val && val.toLowerCase().trim())) this.nameDup = true,console.error('name already exist')
            else this.nameDup = false
			if (this.page.slug == 'index' ){
				this.page.slug = 'index'
			}
			else if (this.page.slug != 'blogpost' && this.page.slug != 'modeldetails' && this.page.slug != 'login'){
            	this.page.slug = madename.slug
			}
        },
    },
    computed:{
        nameError(){
            return this.nameDup || !this.page.name.trim()
        },
        pageSlug(){
            return `/` + this.page.slug
        },
        show:{
            get(){
                console.log('THIS VALUE ID', this.value.id)
                return Boolean(this.value.id)
            },
            set(val){
                if (!val) return this.$emit('close')
            }
        },
    },
    methods:{
        toggleIndex(){
            console.log('hELLOOWOWOWOOWOWOWWOOOWOW', this.page.seo.noIndex)
            this.page.seo.noIndex = !this.page.seo.noIndex
        },
        createSlug(){
            let name = this.page.name
            let slug = this.makeSlug(page.name)
        },
        submit(){
            console.log('SUBMITTTINGGGG')
            this.pageGotEdited(this.page.id)
            this.page.seo.title = this.page.name
            this.$emit('input',this.page)
            this.updateFrame(this.page.id)
            this.$emit('close')
        }
    },
    created(){
        this.page = JSON.parse(JSON.stringify(this.value))
        console.log('THIS PAGEEEEE", this', this.value)
        if (!this.page.seo) this.page.seo = {title:'',description:'',noIndex:false}
        if (!this.page.seo.title) this.page.seo.title = this.page.name
        if (!this.page.seo.description) this.page.seo.description = ''
    },
    mounted(){
    }
}
</script>

<style lang="scss">

</style>
