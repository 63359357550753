<template>
    <div style="position: absolute;left: 0;right: 0;bottom: 0;top: 0;overflow: hidden scroll;">
        <div class="dF aC px-3 py-4" style="gap:1ch;position:sticky;top:0;z-index:10;background:#f1f1f1;z-index:10">
            <a-icon type="arrow-left" class="cursor-pointer" v-if="setting" @click="setting = ''" />
            <h6 class="mb-0" style="font-size: 1.15em;">{{setting ? emailSettings[setting] : 'Project Settings'}}</h6>
        </div>
        <template v-if="!setting">
            <BuilderSettingCard :item="item" v-for="(item,itemKey) in emailSettings" :key="itemKey" @click.native="edit(itemKey)" />
        </template>
        <template v-else>
            <SEOSettings :project="project" v-if="setting === 'seo' " />
            <LogosSettings :project="project" v-if="setting === 'logos' " />
            <FontSettings :project="project" v-if="setting === 'fonts' " />
            <GlobalColors :project="project" v-if="setting === 'colors' " />
            <AdvancedGlobalSettings :project="project" v-if="setting === 'css' " />
            <LegalSettings :project="project" v-if="setting === 'legal' " />
            <TrackerSettings :project="project" v-if="setting === 'tracker' " />
            <MenuSettings :project="project" v-if="setting === 'menu'" />
        </template>
    </div>
</template>

<script>
import BuilderSettingCard from '@/components/site/BuilderSettingCard'
import FontSettings from '../site/FontSettings'
import GlobalColors from '../site/GlobalColors'
import AdvancedGlobalSettings from '../site/AdvancedGlobalSettings'
import LogosSettings from '../site/LogosSettings'
import SEOSettings from '../site/SEOSettings'
import LegalSettings from '../site/LegalSettings'
import TrackerSettings from '../site/TrackerSettings'
import MenuSettings from '../site/MenuSettings'

export default {
    props:['project'],
    components:{
        BuilderSettingCard,
        FontSettings,
        GlobalColors,
        AdvancedGlobalSettings,
        SEOSettings,
        LogosSettings,
        LegalSettings,
        TrackerSettings,
        MenuSettings,

    },
    data:()=>({
        setting:'',
        emailSettings:{
            seo:'SEO Settings',
            menu:'Navigation Menu',
            logos:'Logos Settings',
            colors:'Colors',
            fonts:'Typography',
            css:'Advanced (Scripts/CSS)',
            legal:'Legal Settings',
            tracker:'Analytics / Trackers',
        }
        
    }),
    methods:{
        edit(val){
            this.setting = val
        }
    },
    mounted(){
        console.log('MOUNTED RAN FROM PROJECTSETTINGS')
        if (this.project && this.project.type == 'landing'){
            this.emailSettings = {
                seo:'SEO Settings',
                logos:'Logos Settings',
                colors:'Colors',
                fonts:'Typography',
                css:'Advanced (Scripts/CSS)',
                legal:'Legal Settings',
                tracker:'Analytics / Trackers',
            }
        }
    }
}
</script>

<style>

</style>