<template>
    <div class="bg-white px-3">
        <a-form-model ref="siteForm">
                <a-form-model-item label="Your Site Title" prop="title" help="This will be the title that google search result will show or on the browser.">
                    <a-input
                        v-model="project.seo.title"
                        placeholder="Enter Site Title"
                    />
                </a-form-model-item>

                <a-form-model-item prop="favico" class="empty mt-4">
                    <div style="line-height: 1;">
                        <span style="line-height: 1; color: #40454c; font-size: 14px; margin-bottom: .5rem;">Fav Icon</span>
                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                            <template slot="title">
                                The fav icon is the icon that appears at the top of the browser window.
                            </template>
                            <a-icon class="ml-2" type="question-circle" style="line-height: 25px; margin-top: 5px; color: var(--orange);" />
                        </a-tooltip>
                    </div>
                    <ImageBoxSelector v-model="project.data.favico" type=".png,.ico"/>
                </a-form-model-item>

                <a-form-model-item label="Dark Logo" prop="darkLogo" class="empty mt-4">
                    <ImageBoxSelector v-model="project.data.darkLogo"/>
                </a-form-model-item>

                <a-form-model-item label="Light Logo" prop="lightLogo" class="empty mt-4">
                    <ImageBoxSelector v-model="project.data.lightLogo"/>
                </a-form-model-item>

        </a-form-model>
       
    </div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
    props:['project'],
    components:{ImageBoxSelector},
    data:() => ({
        data:{
            title:'',
            darkLogo:'',
            lightLogo:'',
            favico:'',
        },
        currentStep:0,

        rules: {
            title: [
                { required: true, message: `Please input your site's title`, trigger: 'blur' }
            ],
            darkLogo: [
                { required: true, message: 'Please provide your dark logo' }
            ],
            lightLogo: [
                { required: true, message: 'Please provide your light logo' }
            ],
        },
        onBoarding:false,
    }),
    watch:{
        currentStep(val){
            return this.$emit('input',val)
        }
    },
    computed:{
        instance(){
            return this.$store.state.instance
        },
        theApp(){
            return this.$store.state.theApp
        },
    
    },
    methods:{
        skip(){
            return this.$emit('next')
        },
        next(){
            this.$refs.siteForm.validate(valid => {
                if (valid) {
                    console.log('validddd', valid)
                    return this.$emit('done', this.site)
                    
                } else {
                    console.log('error submit!!');
                }
            });
            
        },

    },
    created(){
        if (!this.project.data.favico) this.project.data.favico = ''
    }
}
</script>

<style>
    .change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
        background:#3F3356;
    }
</style>
