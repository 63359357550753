<template>
    <a-drawer
        placement="right"
        :width="`100%`"
        :closable="false"
        :visible="bPage.show"
        :zIndex="1000"
        wrapClassName="blogDrawer"
        @close="onClose"
        :drawerStyle="{'overflow-y': 'scroll !important'}"
        >
        <bh-loading :show="loading" />
        <div class="dF topbar relative jSB px-3" style="background-color: white; box-shadow: rgb(100 100 100 / 10%) 0px 8px 28px; z-index: 10;">
            <a-popover placement="bottomRight" overlayClassName="popoverStyle">
                <div slot="content">
                    <div @click="saveBlog()" class="popoverContent"><i class="fe fe-save mr-2" />Save</div>
                    <div @click="saveCloseBlog()" class="popoverContent"><i class="fe fe-x-square mr-2" />Save &amp; Close</div>
                    <div @click="onClose()" class="popoverContent" style="color: var(--danger);"><i class="fe fe-trash-2 mr-2" />Discard Changes</div>
                </div>
                <div class="back-button dF aC p-2" style="cursor: pointer;">
                    DONE
                    <a-icon class="ml-1" type="down"  />
                </div>
            </a-popover>
        </div>
        <div class="w-full px-5 py-4">
            <div class="w-full">
                <h4 v-if="bPage.type == 'newBlog'" class="mb-3 mt-3">Add New Blog</h4>
                <h4 v-else class="mb-3 mt-3">Edit Blog</h4>
            </div>
            <div class="blog-page-editor">
                <div class="blog-editor-left f1">
                    <div>
                        <a-input v-model="blogPost.title" placeholder="Enter Title Here" size="large" />
                    </div>

                    <a-card class="mt-5" title="Summary">
                        <a-textarea v-model="blogPost.summary" placeholder="Enter Summary Here" :auto-size="{ minRows: 5, maxRows: 5 }" />
                    </a-card>

                    <a-card class="mt-5" title="Content">
                        <!-- <BlogTextEditor v-if="bPage.show" :text="blogPost.content" @input="textChanged" /> -->
						<RichText v-model="blogPost.content" :all="true" :format="false" builder="web" />
                    </a-card>
                </div>

                <div class="blog-editor-right" style="width: 300px;">
                    <a-card v-if="bPage.show" class="w-full blog-card" title="Status">
                        <a-select v-model="blogPost.status" size="large" style="width: 100%">
                            <a-select-option value="draft">
                                Draft
                            </a-select-option>
                            <a-select-option value="published">
                                Published
                            </a-select-option>
                        </a-select>
                        <a-date-picker v-model="blogPost.date" size="large" class="w-full mt-3" />
                    </a-card>
                    <a-card class="w-full blog-card" title="Featured Image">
                        <ImageBoxSelector class="imageSelector" v-if="bPage.show" :value="blogPost.image" @input="updateImage" :removable="true" />
                    </a-card>
                    <!-- <a-card class="w-full blog-card" title="Categories">
                        <a-select v-model="blogPost.categories" size="large" mode="tags" style="width: 100%" placeholder="Select categories..." @change="handleChange">
                            <a-select-option v-for="cat in blogCategories" :key="cat.name">
                                {{cat.name}}
                            </a-select-option>
                        </a-select>
                    </a-card> -->
                    <a-card class="w-full blog-card" title="SEO" v-if="blogPost.seo">
                        <a-input placeholder="Enter Site Title Here" v-model="blogPost.seo.title" size="large"></a-input>
                        <a-textarea class="mt-3" size="large" v-model="blogPost.seo.description" placeholder="Enter Site Description Here" :auto-size="{ minRows: 3, maxRows: 3 }" />
                    </a-card>
                </div>
            </div>
        </div>
    </a-drawer>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import BlogTextEditor from '@/components/site/BlogTextEditor'

export default {
    props:['bPage', 'bPost', 'loading'],
    components:{
        bhLoading,
        BlogTextEditor,
		RichText: () => import('@/components/common/RichText'),
        ImageBoxSelector: () => import('bh-mod/components/common/ImageBoxSelector'),
    },
    data:()=>({
        blogPage:{},
        blogPost:{},
        blogCategories:[]
    }),
    watch:{
        bPage:{
            handler(val){
                if (val.show){
                    console.log('BLOG PAGE WATCH', val)
                    this.blogPage = val
                    this.blogPost = this.bPost
                    console.log('BLOG POST WATCH', this.blogPost)
                    for (var i = 0; i < this.blogPost.categories.length; i++){
                        this.blogPost.categories[i] = this.blogPost.categories[i].name
                    }
                }
            },
            deep:true
        },
    },
    computed:{

    },
    methods:{
        handleChange(value){
            console.log('SELECTED ', value)
            console.log('BLOG POST', this.blogPost)
        },
        textChanged(e){
            this.blogPost.content = e
        },
        updateImage(url){
            console.log('UPDATE IMAGE RAN', url)
            this.blogPost.image = url
        },
        saveBlog(){
            let obj = JSON.parse(JSON.stringify(this.blogPost))
            for (var i = 0; i < this.blogPost.categories.length; i++){
                for (var j = 0; j < this.blogCategories.length; j++){
                    if (this.blogPost.categories[i] == this.blogCategories[j].name){
                        obj.categories[i] = this.blogCategories[j]
                    }
                }
            }

            if (this.blogPost.title != ''){
                this.$emit('saveBlog', obj)
            } else {
                this.$message.error('Please enter a title!')
            }
        },
        saveCloseBlog(){
            let obj = JSON.parse(JSON.stringify(this.blogPost))
            for (var i = 0; i < this.blogPost.categories.length; i++){
                for (var j = 0; j < this.blogCategories.length; j++){
                    if (this.blogPost.categories[i] == this.blogCategories[j].name){
                        obj.categories[i] = this.blogCategories[j]
                    }
                }
            }

            if (this.blogPost.title != ''){
                this.$emit('saveCloseBlog', obj)
            } else {
                this.$message.error('Please enter a title!')
            }
        },
        onClose(){
            this.$emit('close', '')
        }
    },
    created(){
        this.$api.get(`/blogs-categories/:instance`).then( ({data}) => {
            console.log('BLOG CATEGORIES COMING BACK FROM GET REQUEST', data)
            this.blogCategories = data
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
    }
}
</script>
<style lang="scss" scoped>
    .popoverContent{
        height: 35px;
        // width: 100px;
        line-height: 35px;
        padding-left: 10px;
        padding-right:10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .back-button:hover {
        background-color: var(--off-white-light);;
    }
    @media screen and (min-width: 768px){
        .blog-page-editor {
            display: flex;
        }
        .blog-editor-left {
            margin-right: 2em;
        }
        .blog-card:not(:first-child) {
            margin-top: 2em;
        }
    }
    @media screen and (max-width: 768px){
        .blog-card {
            margin-top: 2em;
        }
    }
</style>

<style lang="scss">
    .slide-fade-enter-active {
        transition: all .25s ease;
    }
    .slide-fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }
    .section-editor-accordion-wrapper{
        +.section-editor-accordion-wrapper{
            border-top:1px solid var(--light-purple);
        }
    }
    .section-add-card{
        gap: 1ch;
        border-top: 0px;
        margin: 5px 5px;
        background: rgb(255, 255, 255);
        border-radius: 4px;
        border: 1px dashed currentColor;
        transition:background-color .3s ease-out;
        &:hover{
            background:#efebe6;
        }
    }
    // .blogDrawer .ant-drawer-wrapper-body {
    //     background-color: rgb(241, 241, 241);
    // }
</style>
