<template >
    <div class=" bg-white px-3 section-card-component dF jSB aC" @mouseover="highlight" @mouseleave="unhighlight">

        <!-- <span class="section-card-tag" v-if="item.tag" v-text="item.tag" /> -->

        <div @click="$emit('edit')" class="dF aC py-3 f1 cursor-pointer hover:text-primary relative" style="gap:1ch;">
            <template v-if="sectionCategory">
				<a-icon :type="categoryIcon" style="font-size: 20px;" />
			</template>
			<img v-else-if="image" :src="image" style="height:13px; width: 20px;" />
            <p class="mb-0 ">{{item.tag ? item.tag : item.name}}</p>

            <!-- <a-popover
            v-if="!item.tag"
            :title="null"
            :visible="renameBool"
            @visibleChange="() => {}"
            >
                <div slot="content">
                    <a-input type="text" />
                    <a @click="$emit('rename',false)">Close</a>
                </div>

                <i class="fe fe-edit-2" @click.stop="$emit('rename-close')" />

            </a-popover> -->



        </div>


        <a-tooltip overlayClassName="change-tooltip-color" title="Drag to rearrange" :mouseEnterDelay="2">
            <a-icon class="handle" v-if="!item.tag" type="menu" style="font-size:11px" />
        </a-tooltip>
    </div>
</template>

<script>
export default {
    props:['item','renameSection'],
    data:() => ({
        renameBool:false,
    }),
    watch:{
        renameSection(val){
            if (val !== this.renameBool) this.renameBool = val
        },
        renameBool(val){
            this.$emit('renameClose')
        },
    },
    computed:{
        image(){
            if (this.item.category == 'Header') return require('@/assets/icons/header-revised.svg')
            // if (this.item.category == 'Form') return require('@/assets/icons/form-revised.svg')
            // if (this.item.category == 'Gallery') return require('@/assets/icons/image-revised.svg')
            // if (this.item.category == 'Testimonials') return require('@/assets/icons/testimonial-revised.svg')
            if (this.item.category == 'Footer') return require('@/assets/icons/footer-revised.svg')
            // if (this.item.category == 'Features and Finishes') return require('@/assets/icons/featured-revised.svg')
            if (this.item.tag) return require(`@/assets/icons/${this.item.tag.toLowerCase()}.svg`)
            if (!this.item.icon) return require(`@/assets/icons/content-revised.svg`)
            if (this.item.icon.indexOf('http') === 0 && this.item.icon.includes('://')){
                return this.item.icon
            }
            return require(`@/assets/icons/content-revised.svg`)
        },
		sectionCategory(){
			let categories = ['Amenities', 'Appointment Booking', 'Banner Image', 'Blog', 'Contact', 'Content', 'Cookies', 'Error 404', 'FAQ', 'Form', 'Gallery', 'Home Model', 'Instagram', 'List', 'Newsletter', 'Service', 'Single Model', 'Siteplan', 'Testimonials', 'Video']
			return categories.includes(this.item.category)
		},
		categoryIcon(){
			let icons = {
				'Amenities': 'environment',
				'Appointment Booking': 'calendar',
				'Banner Image': 'camera',
				'Blog': 'form',
				'Contact': 'contacts',
				'Content': 'pic-left',
				'Cookies': 'check-circle',
				'Error 404': 'warning',
				'FAQ': 'question-circle',
				'Form': 'mail',
				'Gallery': 'picture',
				'Home Model': 'home',
				'Instagram': 'instagram',
				'List': 'ordered-list',
				'Newsletter': 'read',
				'Service': 'shop',
				'Single Model': 'home',
				'Siteplan': 'database',
				'Testimonials': 'sound',
				'Video': 'play-square',
			}
			return icons[this.item.category]
		},
        siteURL(){
            return this.$store.state.appData.siteURL
        },
    },
    methods:{
        test(){
            console.log('CLICKEDDDDDDDDDDDDDD DOUBLE CLICKEEDDD D**D*DD**D*D*D*D*D*D*')
        },
        highlight(){
            if (document.querySelector('iframe') && this.item.id){
                document.querySelector('iframe').contentWindow.postMessage({action:'highlightSection',id:this.item.id},this.siteURL)
            }

        },
        unhighlight(){
            if (document.querySelector('iframe') && this.item.id){
                document.querySelector('iframe').contentWindow.postMessage({action:'unHighlightSection',id:this.item.id},this.siteURL)
            }
        }
    }
}
</script>

<style lang="scss">
    .section-card-component{
        border:1px solid var(--light-purple);
        border-left:0;
        border-right:0;
        position: relative;
        + .section-card-component{
            border-top:0;
        }
    }
    .handle{
        cursor:-moz-grab;
        cursor:-webkit-grab;
    }
    .section-card-tag{
        position: absolute;
        top:3px;
        left:20px;
        font-size:12px;
        margin-left:calc(20px + 1ch);
        color:var(--med-gray);
    }
</style>
