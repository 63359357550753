<template>
    <div class="bg-white p-3">
        <RedirectModal :project="project" :show="modalManage" @cancel="modalManage = false" @ok="updateProject" />
        
        <a-form-model ref="siteForm">
                <a-form-model-item label="Your Site Title" prop="title" help="This will be the title that google search result will show or on the browser.">
                    <a-input
                        v-model="project.seo.title"
                        placeholder="Enter Site Title"
                    />
                </a-form-model-item>

                <a-form-model-item label="Social Share Image" prop="lightLogo" class="empty mt-4" help="Social networks typically show your social sharing image together with your SEO title and description. If you don’t add a social sharing image, we’ll use your social sharing logo or site logo instead.">
                    <ImageBoxSelector v-model="project.seo.image"/>
                </a-form-model-item>
                
        </a-form-model>

        <hr />
        <div class="mt-3">

            <a-button type="primary" icon="login" @click="modalManage = true" >Manage Redirects</a-button>
            <span class="block text-sm text-med-gray">Advanced: Prevent SEO Duplicate Errors with Redirects ( 301 and canonical links )</span>
        </div>    
        
       
    </div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import RedirectModal from './RedirectModal'

export default {
    props:['project'],
    components:{
        ImageBoxSelector,
        RedirectModal
    },
    data:() => ({
        modalManage:false,
        data:{
            title:'',
            darkLogo:'',
            lightLogo:'',
            redirects:{}
        },
        currentStep:0,
        rules: {
            title: [
                { required: true, message: `Please input your site's title`, trigger: 'blur' }
            ],
            darkLogo: [
                { required: true, message: 'Please provide your dark logo' }
            ],
            lightLogo: [
                { required: true, message: 'Please provide your light logo' }
            ],
        },
        onBoarding:false,
    }),
    watch:{
        currentStep(val){
            return this.$emit('input',val)
        }
    },
    computed:{
        instance(){
            return this.$store.state.instance
        },
        theApp(){
            return this.$store.state.theApp
        },
    
    },
    methods:{
        updateProject(incoming){
            console.log('incoming data', incoming)
        },
        
        skip(){
            return this.$emit('next')
        },
        next(){
            this.$refs.siteForm.validate(valid => {
                if (valid) {
                    console.log('validddd', valid)
                    return this.$emit('done', this.site)
                    
                } else {
                    console.log('error submit!!');
                }
            });
            
        },

    }
}
</script>
