<template >
    <div class="bt-next bg-white px-3 cursor-pointer dF jSB aC" >

        <div class="dF aC py-3 f1 hover:text-primary relative" style="gap:1ch;">
            <p class="mb-0 ">{{item}}</p>
        </div>

    </div>
</template>

<script>
export default {
    props:['item'],
    data:() => ({
    }),
    watch:{
       
    },
    computed:{
       
    },
    methods:{
        
       
    }
}
</script>

<style lang="scss">
   .bt-next{
       border-top:1px solid var(--light-purple);
       border-bottom:1px solid var(--light-purple);
       & + .bt-next{
           border-top:0;
       }

   }
</style>