<template >
    <div class="bg-white px-3 section-card-component dF jSB aC">
        
        <div @click="actionClicked({key:'edit'})" class="dF aC py-3 f1 cursor-pointer hover:text-primary relative page-name-holder">
            <div class="blog-icon-image">
                <img v-if="item.image && item.image.length" :src="item.image" style="width: 44px; height: 44px; object-fit: cover;"/>
                <img v-else src="" style="width: 44px; height: 44px; object-fit: cover;"/>
            </div>

            <div class="mb-0" :style="`width:calc(${width}px - 2ch); padding-left: 1ch;`">
                <div style="font-size: 16px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    {{item.title}}
                </div>
                <div style="color: var(--med-gray); font-size: 14px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    {{convertDate(parseInt(item.date))}}
                </div>
            </div>
        </div>
        
        <MoreActions :items="actions" @action="actionClicked" />
        
    </div>
</template>

<script>

import MoreActions from 'bh-mod/components/common/MoreActions'

export default {
    props:['item'],
    components:{MoreActions},
    data:() => ({
        width:200,
        months:[
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ],
        actions:[
            {
                label:'Edit Blog',
                value:'edit',
                icon:'fe-edit',
            },
            {
                label:'Rename',
                value:'rename',
                icon:'fe-type',
            },
            // {
            //     label:'Blog Settings',
            //     value:'settings',
            //     icon:'setting',
            // },
            {
                label:'Duplicate',
                value:'duplicate',
                icon:'fe-copy',
            },
            {
                label:'Delete',
                value:'delete',
                icon:'fe-trash',
            },
        ]
    }),
    watch:{
       
    },
    computed:{
        
    },
    methods:{
        convertDate(num){
            let x = new Date(num)
            //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
            let year = x.getFullYear()
            let month = this.months[x.getMonth()]
            let day = x.getDate()
            return month+' '+day+', '+year
        },
        actionClicked(e){
            this.$emit('actionclicked', {key:e.key,blog:this.item})
        },
    },
    mounted(){
        this.width = this.$el.querySelector('.page-name-holder').clientWidth - 20
    }
}
</script>

<style lang="scss">
   .section-card-component{
        border:1px solid var(--light-purple);
        border-left:0;
        border-right:0;
        position: relative;
        + .section-card-component{
            border-top:0;
        }
    }
    .handle{
        cursor:-moz-grab;
        cursor:-webkit-grab;
    }
    .section-card-tag{
        position: absolute;
        top:3px;
        left:20px;
        font-size:12px;
        margin-left:calc(20px + 1ch);
        color:var(--med-gray);
    }
</style>