<template>
    <div>
        <div class="px-3 bg-white py-4">

            <div style="gap:2ch;">
                <div class="text-md mb-2">Google Analytics</div>
                <div>
                    Enable ? <a-switch :size="analytics.gtag.enabled ? 'small' : 'large' " v-model="analytics.gtag.enabled" />
                </div>
                <div v-if="analytics.gtag.enabled">
                    <p class="mb-0 mt-2">Add GTag / GTM code(s)</p>
                    <small class="text-med-gray">If multiple, seperate by comma</small>
                    <a-textarea v-model="analytics.gtag.id" placeholder="G-XXXX, AW-XXXXX, UA-XXXXX, GTM-XXXX" />
                </div>
            </div>

            <div style="gap:2ch;" class="mt-4">
                <div class="text-md mb-2">Facebook Pixel</div>
                <div>
                    Enable ? <a-switch :size="analytics.fpixel.enabled ? 'small' : 'large' " v-model="analytics.fpixel.enabled" />
                </div>
                <div v-if="analytics.fpixel.enabled">
                    <p class="mb-0 mt-2">Pixel ID</p>
                    <a-input v-model="analytics.fpixel.id" />
                </div>
            </div>



        </div>

    </div>
</template>

<script>

    import {getAppIcon,validateEmail,setProp} from 'bh-mod'
    import PopupWYSWYG from '@/components/site/PopupWYSWYG'

    export default {
        props:['project'],
        components:{
            PopupWYSWYG
        },
        data:() => ({
            analytics:{
                gtag:'',
                fpixel:''
            }
        }),
        computed:{
            isPixel:{
                get(){

                },
                set(val){

                }
            },
            isGtag(){

            },
            fontList(){
                let fonts = this.$store.state.appData.fontList
                let obj = {}
                fonts.forEach(item => {

                    let boldWeights = []

                    let hasBold = false
                    let boldVariant = 0

                    let italicWeights = []
                    let hasItalics = false
                    let italicVariant = 0

                    let boldItalicWeights = []
                    let hasBoldItalics = false
                    let boldItalicVariant = 0



                    item.weights = item.variants.map( wght => {

                        if (wght === 'regular') return '400'
                        else if (wght === 'italic') return '400i'

                        if (wght.indexOf('italic') > 0) return wght.replace('italic','i')

                    })
                    item.weights.forEach( weight => {

                        let w = parseInt(weight)
                        if (isNaN(w)) return

                        if (weight.includes('i')){
                            hasItalics = true
                            italicWeights.push(w)
                            if (w > 250 && w < 600){
                                hasItalics = true
                                if (w === 400){

                                }

                            } else if (w > 600) {

                            }

                        } else if (w > 650) {

                            if (w == 700) {

                                hasBold = true
                                boldVariant = 700

                            } else if (w == 800) {

                                hasBold = true
                                boldVariant = 800

                            } else if (w == 900) {

                                hasBold = true
                                boldVariant = 900

                            }

                        }

                    })


                    item.category = item.category === 'handwriting' ? 'cursive' : item.category
                    item.hasBold = hasBold
                    item.boldVariant = boldVariant
                    obj[item.family] = item

                })
                return obj
            },
            fontObj(){
                if (!Object.keys(this.fontList).length) return {}
                if (!this.fonts) return {};

                let {primary,secondary,body,accent} = this.fonts
                let chosenPrimary = this.fontList[primary]
                let chosenSecondary = this.fontList[secondary]
                let chosenBody = this.fontList[body]
                let chosenAccent = this.fontList[accent]


                let makeFontObj = (f,cat = 'sans-serif',t) => {
                    f = f && f.trim()
                    let bold = this.bold[t]
                    let italic = this.italic[t]
                    let weights = ['400']

                    if (bold && italic) weights = ['700i']
                    else if (bold) weights = ['700']
                    else if (italic) weights = ['400i']

                    return {
                        name:f,
                        google:f && f.replace(/ /g, '+'),
                        fontText:`'${f}', ${cat}`,
                        bold,
                        italic,
                        weights
                    }
                }

                console.log('primary => ', primary)
                console.log('chosenPrimary => ', this.fontList)

                console.log('secondary => ', secondary)
                console.log('chosenSecondary => ', this.fontList[secondary])


                primary = makeFontObj(primary, chosenPrimary.category,'primary')
                secondary = makeFontObj(secondary, chosenSecondary.category,'secondary')
                body = makeFontObj(body, chosenBody.category,'body')
                accent = makeFontObj(accent, chosenAccent.category,'accent')

                return {
                    primary,
                    secondary,
                    body,
                    accent
                }
            },
            instance(){
                return this.$store.state.instance
            },
        },
        watch:{
            fontObj:{
                deep:true,
                handler(val){
                    this.project.fonts = {...val}
                }
            }

        },
        methods:{
            resetModal(){
                this.edit = {
                    title:'',
                    type:'',
                    show:false,
                    content:'',
                }
            },
            saveContent(data){
                this.project.data[this.edit.type].content = data
                this.resetModal()
            },
            editContent(type){
                this.edit.title = type === 'terms' ? 'Terms & Conditions' : 'Privacy Policy'
                this.edit.type = type
                console.log('THIS PROJECT', this.project.data)
                this.edit.content = this.project.data[type].content
                this.edit.show = true
            },
            getStyle(t){

                if (!t) return ''
                let style = ''

                if (this.bold[t]) style += 'font-weight:700;'
                if (this.italic[t]) style+= 'font-style:italic'

                return style

            },
            fontSelected(e,f){

                this.fonts[f] = e.family

            },
            generateFont(){

                if (this.fontList && this.fontList.length) return



            },
            next(){

                return this.$emit('done', {
                    fonts:this.fontObj
                })

            },


        },
        created(){
            if (!this.project.data.gtag) this.project.data.gtag = {enabled:false,id:''}
            if (!this.project.data.fpixel) this.project.data.fpixel = {enabled:false,id:''}
            setProp(this,['analytics'],this.project.data)
        }
    }
</script>

<style lang="scss" scoped>
    .maxed-height{
        height: 100px;
        overflow: hidden;
        position: relative;
        margin-bottom:-10px;
        background:rgba(244,244,244,1);
        padding:3px 6px;
        font-size:14px;
        &:after{
            content:'';
            width:100%;
            left:0;
            position:absolute;
            bottom:0;
            height:100px;
            background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
        }
    }

</style>
