<template>
  <div class="editor">
    <editor-menu-bubble :editor="editor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
      <div
        class="menububble text-commands"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px; border: 1px solid #eee; border-bottom: none;`"
      >

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
            <a-icon type="bold"></a-icon>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
            <a-icon type="italic"></a-icon>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <a-icon type="underline"></a-icon>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <a-icon type="unordered-list" />
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <a-icon type="ordered-list" />
        </button>

        <!-- <button
          class="menububble__button"
          :class="{ 'is-active': isActive.heading({level:1}) }"
          @click="commands.heading({level:1})"
        >
            H1
        </button> -->

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.heading({level:2}) }"
          @click="commands.heading({level:2})"
        >
            H2
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.heading({level:3}) }"
          @click="commands.heading({level:3})"
        >
            H3
        </button>

        <a-popover :visible="showLinkOptionsBoolean" title="Apply Link" trigger="click">
          <div class="button-editor-container" slot="content">

              <template v-if="form.type === 'link'">
                  <p style="line-height:1" class="my-2">External Link (URL)</p>
                  <a-input v-model="form.value" />
              </template>
              <template v-else-if="form.type === 'page'">
                  <p style="line-height:1" class="my-2">Select a Page</p>
                  <a-select v-model="form.value" :options="pagesOptions" />
              </template>
              <div class="mt-4 dF" style="gap:1ch;">
                  <a-button type="primary" @click="applyLink(commands,isActive)">Apply</a-button>
                  <a-button @click="cancelLinkOptions()">Cancel</a-button>
              </div>
          </div>
          <button class="editor-menubar__button" @click="() => showLinkOptions(commands,isActive)">
              <a-icon type="link" :class="isActive.link()? 'text-purple' : 'text-med-gray'"></a-icon>
          </button>
      </a-popover>

      </div>
    </editor-menu-bubble>

    <editor-content @update="editDescription" class="editor__content editor-textbox" :editor="editor" style="height: 400px;" />
  </div>
</template>

<script>

import { Editor, EditorContent, EditorMenuBar,EditorMenuBubble } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Strike,
  Underline,
  Link,
  History,
  Mention,
} from 'tiptap-extensions'

import tippy, { sticky } from 'tippy.js'

export default {
    props:{
        text:{
            default:'',
            type:String
        }
    },
  components: {
        EditorContent,
        EditorMenuBar,
        EditorMenuBubble,
  },
  computed:{
      editTask(){
        return this.$store.state.contacts.editTask.task
      },
      linkType(){
          let types =  [{label:'External Link',value:'link'},{label:'Goto Section',value:'section'}]
          if (!this.$store.getters.isLanding) types.push({label:'Goto Page',value:'page'})
          return types
      },
      pageSectionsOptions(){
          return this.$store.getters.pageSectionsOptions
      },
      pagesOptions(){
          return this.$store.getters.pagesOptions
      },
  },
  data() {
    return {
      keepInBounds: true,
      editor: null,
      editButton:false,

      form:{
        type: "link",
        value:'',
      },
      linkUrl: null,
      showLinkOptionsBoolean: false,
      editor: null,
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      forceClose:false,
    }
  },
  watch:{
      'form.type'(val){
          if (val === 'page') {
              this.form.value = this.pagesOptions[0].value
          } else {
              this.form.value = ''
          }
      }
  },
  methods:{
      editDescription() {
			let sendObj = JSON.parse(JSON.stringify(this.editTask))
			sendObj.description = this.editor.view.dom.innerHTML
			this.$api.put(`/tasks/:instance/${sendObj.id}`, sendObj).then(({ data }) => {
				console.log('update task', data)
				this.$store.commit('UPDATE_TASK', data)
				this.editButton = false
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		},
      cancelLinkOptions(){
        this.showLinkOptionsBoolean = false;
      },
      showLinkOptions(commands,isActive){
        this.form = {
            type: "link",
            value:'',
        }

        if (isActive.link()) {
            this.showLinkOptionsBoolean = false
            return commands.link({ href: null })
        }

        return this.showLinkOptionsBoolean = true
      },
      renderPopup(node) {
          if (this.popup && this.popup.length && !this.variable) {
              return console.error('POPUP ALREADY EXISTS')
          }

          // ref: https://atomiks.github.io/tippyjs/v6/all-props/

          this.popup = tippy(this.$el, {
              getReferenceClientRect: node.getBoundingClientRect,
              appendTo: () => document.body,
              interactive: true,
              sticky: true, // make sure position of tippy is updated when content changes
              plugins: [sticky],
              content: this.$refs.suggestions,
              trigger: 'mouseenter', // manual
              showOnCreate: true,
              theme: 'dark',
              placement: 'top-start',
              inertia: true,
              duration: [400, 200],
          })
      },
      destroyPopup() {
          if (this.popup && this.popup[0]) {
              this.popup[0].destroy()
              this.popup = null
          }
      },
      setLink(commands,active){
          if (active) return commands.link({ href: null })
          commands.link({ href: 'https://www.google.com', name:'goooooogle' })
          this.showLinkOptionsBoolean = false
      },
      applyLink(commands,isActive){
          let value = this.form.value

          commands.link({ href: value})
          this.showLinkOptionsBoolean = false
          this.form = {
              type: "link",
              value:'',
          }
      },
  },
  created(){
      window.tippy = tippy
      this.editor = new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        onUpdate: ({getHTML}) => {
            this.$emit('input', this.editor.getHTML())
        },
        onBlur:() =>{

            // if (this.updatedHTML == null) {
            //     this.updatedHTML = ''
            // }
            // console.log('HTML', this.updatedHTML)
            // this.$emit('save', this.updatedHTML)
        },
        content: `
          <div>
            ${this.text}
          </div>

        `,
      })
  },
  beforeDestroy() {
    this.destroyPopup()
    this.editor.destroy()
  },
  mounted(){

  }
}
</script>

<style lang="scss">
    .text-commands .menububble__button {
        border: none;
        background-color: white;
    }
    .text-commands .is-active {
        color: var(--orange);
    }
    .editor-textbox .ProseMirror {
        height: 378px;
        overflow-y: scroll;
    }
</style>
