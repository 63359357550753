<template>
    <div class="manage-section h-full dF fC" v-if="$store.getters.currPage && $store.getters.currPage.name">
        <a-modal :title="'Rename Section'" :centered="true" :visible="renameModal.visible" @cancel="closeRename" @ok="submitRename">
            <a-form-model>
                <a-form-model-item v-if="renameModal.visible" label="Section Name">
                    <a-input v-model="renameModal.name" :placeholder="'Section Name'" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
		<div class="dF aC px-3 py-4" style="gap:1ch;" :class="editSectionObj.show && editSectionObj.item? 'bg-primary text-white' : ''">
            <template v-if="editSectionObj.show && editSectionObj.item">
                <!-- <i class="fa fa-times hover:text-primary" style="cursor:pointer" @click="editSectionObj.show = false"></i> -->
                <a-icon type="arrow-left" class="cursor-pointer" @click="$store.commit('SET_PROP', {where:['builder','currentSection'],what:''})" />
                <h6 class="mb-0" :class="editSectionObj.show && editSectionObj.item? 'text-white' : ''" >{{editSectionObj.item.name}}</h6>
				<svg @click="showRenameModal" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit" style="cursor: pointer;"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
            </template>
            <div v-else>
                <h6 class="mb-0" style="font-size: 1.15em;">
                    <small class="block text-med-gray" v-if="!$store.state.appData.builder.isLanding"><span>Current Page:</span> {{$store.getters.currPage.name}}</small>
                    Sections
                </h6>
                <div v-if="$store.getters.currPage && $store.getters.currPage.slug === 'modeldetails'" class="mt-2">
                    <label>Select Model to Preview page</label>
                    <a-select v-model="previewModel" :options="Object.values($store.getters.previewModelList)" class="w-full" />
                </div>
            </div>

        </div>

        <transition mode="out-in" name="slide-fade">
            <EditSection :fontList="fontList" v-if="editSectionObj.show" :obj="editSectionObj" key="editSections">
                <div class="mt-4 dF px-3" style="gap:1ch">
                    <a  href="#" @click.prevent="replaceSection" class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc">
                        <i class="fe fe-refresh-ccw" /> Replace
                    </a>
                    <a-popover trigger="click"  placement="left" :auto-adjust-overflow="true" @visibleChange="saveSectionName = ''" :overlayStyle="{width:'350px'}">
                        <a-form slot="content">
                            <div>
                                <p class="mb-1 text-primary" style="font-size:16px;">Save this section with current options. to reuse in other project designs. </p>
                            </div>
                            <a-form-item label="Name your Section">
                                <a-input-search placeholder="Your custom Section" @search="saveSection" v-model="saveSectionName">
                                    <a-button slot="enterButton" type="primary" icon="check" />
                                </a-input-search>
                            </a-form-item>
                        </a-form>
                        <a v-if="!editSectionObj.item.tag" href="#" class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc;">
                            <a-icon type="save" />
                        </a>
                    </a-popover>
                    <a v-if="editSectionObj.item.restriction === 'none'" href="#" @click.prevent="removeSection(editSectionObj.item.id)" class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc; border-color:var(--danger); color:var(--danger)">
                        <a-icon type="minus-circle" /> Remove
                    </a>


                </div>
            </EditSection>
            <div v-else  key="viewSections">
                <div class="mb-3">
                    <SectionCard v-for="item in getHeaders" :key="item.id" :item="item" @edit="editSection(item)"  />
                </div>

                <!-- SECTIONS -->
                <div class="list-group mb-3">

                    <draggable :list="sections" handle=".handle" @change="(e) => reorder(e)" :delay-on-touch-only="true" :delay="100">
                        <SectionCard  @rename-close="renameSection = false" @rename="renameSection = item.id" :rename-section="renameSection===item.id" @edit="editSection(item)" :item="item"  v-for="item in sections" :key="item.id" />
                    </draggable>

                    <div @click="addSection" class="px-4 section-card-component dF aC jC text-primary cursor-pointer section-add-card">
                        <a-icon type="plus-circle" />
                        <p class="mb-0 py-3 cursor-pointer text-primary hover:text-primary">Add section</p>
                    </div>

                </div>

                <div class="footer">
                    <SectionCard v-for="item in getFooters" :key="item.id" :item="item" @edit="editSection(item)"  />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {setProp} from 'bh-mod'
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import ImageBoxAlt from 'bh-mod/components/common/ImageBoxAlt'
import ImageBoxSelectorGroup from 'bh-mod/components/common/ImageBoxSelectorGroup'
import SectionCard from '@/components/site/SectionCard'
import ColorPicker from '@/components/site/ColorPicker'
import ColorDialog from '@/components/site/ColorDialog'
import EditSection from '@/components/site/EditSection'
import draggable from 'vuedraggable'

function modify(obj, newObj) {

  Object.keys(obj).forEach(function(key) {
    delete obj[key];
  });

  Object.keys(newObj).forEach(function(key) {
    obj[key] = newObj[key];
  });

}
export default {
    components:{
        ImageBoxSelector,ColorDialog,ColorPicker,draggable,ImageBoxSelectorGroup,ImageBoxAlt,SectionCard,EditSection,
    },
    props:['project','event','page'],
    inject:['updateFrame'],
    data:()=>({
        msg:'hey',
        editSectionObj:{
            show:false,
            item:{}
        },
        saveSectionName:'',
        renameSection:'',
		renameModal:{
			visible:false,
			name:''
		}
    }),
    watch:{
        event(val){

            if (typeof val === 'object'){

                if (val.action === 'sectionEdit'){

                    let id = val.id.replace('section-id-','')
                    let item = this.page.sections.find(x => x.id === id)
                    if (val.classList.includes('section-header') || val.classList.includes('section-footer')){
                        if (this.page.header.id === id)item = this.page.header
                        else item = this.page.footer
                        item.tag = this.page.header.id === item.id ? 'header' : 'footer'
                    }
                    console.log('ABOUT TO EDIT', item)
                    if (item){
                        this.editSection(item)
                    }

                }

            }
        },
        'editSectionObj.show'(val){
            if (!val){
                document.querySelector('iframe').contentWindow.postMessage({action:'unSelect'},this.siteURL)
            }
        },
        selectedID(val){
            if (val){


                let item = this.sections.find(x => x.id === val)


                if (!item && this.project && this.project.headers && this.project.headers.length){
                    item = this.project.headers.find(x => x.id === val)
                }
                if (!item && this.project && this.project.footers && this.project.footers.length){
                    item = this.project.footers.find(x => x.id === val)
                }

                if (!item && this.page && this.page.header && this.page.header.id === val){
                    item = this.page.header
                }

                if (!item && this.page && this.page.footer && this.page.footer.id === val){
                    item = this.page.footer
                }



                if (!item) {
                    return this.editSectionObj.show = false
                }

                console.log('itemmm',item)

                this.editSectionObj.show = true
                this.editSectionObj.item= item

                document.querySelector('iframe').contentWindow.postMessage({action:'selectedSection',id:item.id},this.siteURL)

            } else {
                this.editSectionObj.show = false
            }
        }
    },
    computed:{
        previewModel:{
            get(){
                return this.$store.state.appData.builder.previewModel
            },
            set(val){
                this.$store.commit('SET_PROP', {where:['builder','previewModel'],what:val})
            }
        },
        selectedID(){
            return this.$store.state.appData.builder.currentSection
        },
        getHeaders(){
            if (this.page.header && this.page.header.id) return [{...this.page.header,tag:'Header'}]
            if (this.project.headers && this.project.headers.length) return this.project.headers.map(x => ({...x,tag:'Header'}))
            return false
        },
        getFooters(){
            if (this.page.footer && this.page.footer.id) return [{...this.page.footer,tag:'Footer'}]
            if (this.project.footers && this.project.footers.length) return this.project.footers.map(x => ({...x,tag:'Footer'}))
            return false
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        siteURL(){
            return this.$store.state.appData.siteURL
        },
        fontList(){
            return Object.values(this.project.fonts)
                .map( ({name,fontText}) => ({
                    label:name,
                    value:fontText
                }))
        },
        pages(){
            return this.project.pages || {}
        },
        sections(){
            return this.page.sections || []
        }
    },
    methods:{
		submitRename(){
			console.log('Submitting Rename...')
			this.editSectionObj.item.name = this.renameModal.name
			this.renameModal.visible = false
			this.renameModal.name = ''
		},
		closeRename(){
			console.log('Closing Rename Modal...')
			this.renameModal.visible = false
			this.renameModal.name = ''
		},
		showRenameModal(){
			console.log('Showing Rename Modal...')
			this.renameModal.visible = true
			this.renameModal.name = this.editSectionObj.item.name
		},
        reorder(e){
            console.log('Reordering', e)
            this.$store.commit('REORDER_SECTIONS')
        },
        async saveSection(){
            let section = JSON.parse(JSON.stringify(this.editSectionObj.item))
			console.log('SAVED SECTION', section)
            console.log('WANT TO SAVE THIS', section, 'with this name => :', this.saveSectionName)
			section.settings.forEach(x => {
				if (x.children){
					x.children.forEach(y => {
						if (section.data.hasOwnProperty(y.id)){
							y.default = section.data[y.id]
						}
					})
				}
			})
            delete section.id
            delete section._id
            section.instance = this.$store.state.instance.id
            section.name = this.saveSectionName
			section.default = true
			section.custom = true
			try {
				let { data } = await this.$api.post(`/sections/:instance`, section)
				this.$store.commit('SET_PROP', { where: ['sections', data.id], what: data })
				this.saveSectionName = ''
				this.$message.success('Section saved!')
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
        },
        addSectionCallBack(item){
            if (!item.data) item.data = {}
            item.slug = 'W' + Date.now()
            item.id = "add_" + item.id + Date.now()

            console.log('ITEM.bocks', item.blocks)
            if (item.blocks && !item.blocks.blocksData) item.blocks.blocksData = []
            if (item.settings && item.settings.length){
                let settings = JSON.parse(JSON.stringify(item.settings))
                settings.forEach( (setting) => {

                   if (setting.hasOwnProperty('default')){
                        item.data[setting.id] = setting.default
                    }else if (setting.children && setting.children.length){
                        setting.children.forEach(childS => {
                            if (childS.hasOwnProperty('default')){
                                item.data[childS.id] = childS.default
                            }
                        })
                    }

                })
            }
            this.page.sections.push(item)
            this.editSection(item)
        },
        replaceSectionCallback(item){


            item.id = `add_${item.id}`

            if (!item.data) item.data = {}

            console.log('ITEM.bocks', item.blocks)
            if (item.blocks && !item.blocks.blocksData) item.blocks.blocksData = []
            if (item.settings && item.settings.length){
                let settings = JSON.parse(JSON.stringify(item.settings))
                settings.forEach(setting => {

                    if (setting.hasOwnProperty('default')){
                        item.data[setting.id] = setting.default
                    }else if (setting.children && setting.children.length){
                        setting.children.forEach(childS => {
                            if (childS.hasOwnProperty('default')){
                                item.data[childS.id] = childS.default
                            }
                        })
                    }
                })
            }

            let restriction = this.editSectionObj.item.restriction || 'none'
            if (restriction === 'header'){
                console.log('CHANGING HEADER =======>')
                if (this.project.headers && this.project.headers[0]){
                    console.log('CHANGING PROJECT HEADER')
                    setProp(this.project.headers,[0],item)

                } else {
                    console.log('CHANGING PAGEE HEADER')
                    setProp(this.page,['header'],item)
                }
                console.log('<=======  CHANGED HEADER')

            }else if (restriction === 'footer'){

                if (this.project.footers && this.project.footers[0]){

                    setProp(this.project.footers,[0],item)

                } else {
                    setProp(this.page,['footer'],item)
                }

            } else {
                console.log('CHANGING SECTION')
                let sections = this.sections.map(x => x.id === this.editSectionObj.item.id ? item : x )
                setProp(this.page,['sections'],sections)
                // setProp(this.editSectionObj,['item'],item)
            }

            this.updateFrame()
            setProp(this.editSectionObj,['item'],item)
            this.editSectionObj.item.id = item.id

            this.editSection(item)
        },
        replaceSection(id){
            this.$store.commit('SET_PROP',{where:['sectionModal'],what:{init:true, show:true, cb:this.replaceSectionCallback, restriction:this.editSectionObj.item.restriction, replace:true }})
        },
        addSection(){
            this.$store.commit('SET_PROP',{where:['sectionModal'],what:{init:true, show:true, cb:this.addSectionCallBack, restriction:'none' }})
        },

        editSection(item){
            console.log('ITEM SELECTED', item)
            this.$store.commit('SET_PROP', {where:['builder','currentSection'],what:item.id})
        },
        removeSection(id){
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Section?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk:() => {
                    console.log('DELETING SECTION', id)
                    this.page.sections = this.page.sections.filter(x => x.id !== id)
                    this.$store.commit('SET_PROP', {where:['builder','currentSection'],what:''})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
    }
}
</script>

<style lang="scss">
    .slide-fade-enter-active {
        transition: all .25s ease;
    }
    .slide-fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }
    .section-editor-accordion-wrapper{
        +.section-editor-accordion-wrapper{
            border-top:1px solid var(--light-purple);
        }
    }
    .section-add-card{
        gap: 1ch;
        border-top: 0px;
        margin: 5px 5px;
        background: rgb(255, 255, 255);
        border-radius: 4px;
        border: 1px dashed currentColor;
        transition:background-color .3s ease-out;
        &:hover{
            background:#efebe6;
        }
    }
</style>
