<template>
    <div class="bg-white px-3 pt-3 pb-5">
        <a-modal v-model="dialog.show" :title="dialog.title" @ok="handleDialogOK" @cancel="closeDialog">
            <a-form-model :model="menuItem" ref="menuItem">
                <a-row :gutter="16">
                    <a-col :span="18">
                        <a-form-model-item :label="`Enter menu name`" prop="label" :rules="{required:true,message:'Name is required'}" class="mb-1">
                            <a-input v-model="menuItem.label" :placeholder="dialog.placeholder" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item :label="menuItem.important ? 'Menu Style: Button' : 'Menu Style: Link'" prop="important" class="mb-1">
                            <a-switch v-model="menuItem.important" />
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <a-form-model-item :label="`Menu type`" prop="type" :rules="{required:true,message:'Type is required'}" class="mb-1">
                    <a-select v-model="menuItem.type" :options="[{label:'Pages',value:'page'},{label:'External Link',value:'external'}]" />
                </a-form-model-item>
                <template v-if="menuItem.type === 'page'">
                    <a-form-model-item :label="`Choose Page`" prop="link" class="mb-1">
                        <a-select v-model="menuItem.link" :options="projectPages" />
                    </a-form-model-item>
                    <template v-if="pageSections && pageSections.length">
                        <a-form-model-item :label="`Choose a Section (Optional)`" prop="section" class="mb-1" style="max-width:300px;">
                            <a-select v-model="menuItem.section" :options="pageSections" />
                        </a-form-model-item>
                    </template>
                </template>
                <template v-else>
                     <a-form-model-item :label="`Enter external link `" prop="link" class="mb-1">
                        <a-input v-model="menuItem.link" placeholder="https://...." />
                    </a-form-model-item>
                </template>
            </a-form-model>

        </a-modal>
        <draggable :list="project.menu"  handle=".handle" class="nav-menu-items-wrapper" :delay-on-touch-only="true" :delay="100">
            <a-collapse v-for="(item,idx) in project.menu" :key="idx" :defaultActiveKey="idx === 0 ? '1' : '0'" class="nav-menu-items">
                <a-collapse-panel key="1" :header="item.label" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;'">
                    <div slot="extra">
                        <a-icon class="handle mr-2" type="menu"/>
                        <a-icon class="hover:text-primary mr-2" type="edit" @click.stop="editItemDialog(item)" />
                        <a-icon class="hover:text-primary" type="delete" @click.stop="deleteItem(item.id)" />
                    </div>
                    <template v-if="item.children">
                        <draggable :list="item.children"  handle=".handle2" :delay-on-touch-only="true" :delay="100">
                            <div v-for="(childItem,idx) in item.children" :key="idx" class="px-3 py-2 dF jSB" style="background:#efefef; border-bottom:1px solid #ddd">
                                <div>
                                    {{childItem.label}}
                                </div>
                                <div >
                                    <a-icon class="handle2 mr-2" type="menu" style="font-size:11px;cursor:grab;" />
                                    <a-icon class="hover:text-primary mr-2 cursor-pointer" type="edit" @click.stop="editItemDialog(childItem,item.id)" style="font-size:11px" />
                                    <a-icon class="hover:text-primary cursor-pointer" @click.stop="deleteItem(childItem.id,item.id)" type="delete" style="font-size:11px" />
                                </div>
                            </div>
                        </draggable>
                    </template>
                    <a-button class="mt-2" icon="plus" @click="addItemDialog(item.id)" ghost type="primary" style="border:0;">Add sub menu</a-button>


                </a-collapse-panel>
            </a-collapse>
             <div  class="py-2">
                <a-button icon="plus" @click="() => addItemDialog()" ghost type="primary">Add menu item</a-button>
            </div>

        </draggable>

    </div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import draggable from 'vuedraggable'
export default {
    props:['project'],
    components:{ImageBoxSelector,draggable},
    data:() => ({
        init:false,
        visible:[],
        menuItem:{
            label:'',
            link:'',
            section:'',
            type:'page',
            important:false,
        },
        dialog:{
            show:false,
            edit:false,
            id:'',
            parent:'',
            title:'',
        },
        currentStep:0,

        rules: {
            title: [
                { required: true, message: `Please input your site's title`, trigger: 'blur' }
            ],
            darkLogo: [
                { required: true, message: 'Please provide your dark logo' }
            ],
            lightLogo: [
                { required: true, message: 'Please provide your light logo' }
            ],
        },
        onBoarding:false,
    }),
    watch:{
        'menuItem.type'(val,oldVal){
            if (oldVal === 'page') {
                console.log('OLD VAL IS PAGE')
                this.menuItem.link = ''
            } else if (val === 'page'){
                this.menuItem.link = this.projectPages[0].value
            }

        }
    },
    computed:{
       projectPages(){
           return this.project.pages.map(page => ({
               label:page.name,
               value:'/'+page.slug
           }))
       },
       pageSections(){
           let result = {
               label:'None',
               value:''
           }
           if (!this.menuItem.link) return []
           let foundPage = this.project.pages && this.project.pages.find(x => x.slug === this.menuItem.link)
           if (!foundPage || !foundPage.sections || !foundPage.sections.length) return []
           result = [result,... foundPage.sections.map( sec => ({
               label:sec.name,
               value:sec.slug
           }))]
           return result
       }


    },
    methods:{
        addItemDialog(parent){
            this.dialog.title = 'Add Menu'
            this.dialog.parent = parent
            this.dialog.show = true
        },
        deleteItem(id,parent){
            console.log('DELETE', id)
            if (parent){
                if (!this.project.menu.find(x => x.id === parent)) return this.$message.error('could not find parent')
                let findIndex = this.project.menu.findIndex(x => x.id === parent)
                if (findIndex > -1 && this.project.menu[findIndex] && this.project.menu[findIndex].children && this.project.menu[findIndex].children.length){
                    let foundChildIndex = this.project.menu[findIndex].children.findIndex(x=> x.id === id)
                    if (foundChildIndex > -1){
                        this.project.menu[findIndex].children.splice(foundChildIndex,1)
                    }


                }
            } else {

                let findIndex = this.project.menu.findIndex(x => x.id === id)
                console.log('FINDINDEX', findIndex)
                if (findIndex > -1){
                    this.project.menu.splice(findIndex,1)
                }
            }
        },
        editItemDialog(item,parent){
            this.dialog.title = `Edit Item: ${item.label}`
            this.dialog.show = true
            this.dialog.id = item.id
            if (parent){
                this.dialog.parent = parent
            }

            this.menuItem = JSON.parse(JSON.stringify(item))
        },
        handleDialogOK(){
            const err = (msg = 'Error occured while trying to update menu') => {
                this.$message.error(msg)
                return this.closeDialog()
            }
            this.$refs.menuItem.validate( async res => {
                console.log('RESSSSSS',this.menuItem,this.dialog)
                if (this.dialog.id){

                    let found = this.project.menu.find(x => x.id === this.dialog.id)
                    if (this.dialog.parent) {

                        found = this.project.menu.find(x => x.id === this.dialog.parent)
                        if (found && found.children && found.children.length){
                            console.log('FOUND PARENT', found)
                            found = found.children.find(x => x.id === this.dialog.id)
                        }

                    }

                    if (!found) return err()

                    console.log('FOUND', found, this.menuItem)

                    Object.entries(this.menuItem).forEach( ([key,value]) => {
                        found[key] = this.menuItem[key]
                    })

                } else {

                    let menuItem = JSON.parse(JSON.stringify(this.menuItem))
                    menuItem.id = Date.now() + '-' + ((Math.floor(Math.random() * 10)) + '' + (Math.floor(Math.random() * 10)))
                    if (this.dialog.parent){
                        menuItem.id = this.dialog.parent + '-' + ((Math.floor(Math.random() * 10)) + '' + (Math.floor(Math.random() * 10)))
                        this.project.menu.forEach(item => {
                            if (item.id === this.dialog.parent){
                                if (!item.children) item.children = []
                                item.children.push(menuItem)
                            }
                        })
                    } else {
                        this.project.menu.push(menuItem)
                    }
                }

                this.closeDialog()

            })
        },
        closeDialog(){
            this.dialog = {
                show:false,
                edit:false,
                id:'',
                parent:'',
                title:'',
            }
            this.menuItem = {
                label:'',
                link:'',
                section:'',
                type:'page',
                important:false,
            }
        },

        handleClick(e){
            console.log('CLICKED', e)
            e.stopPropagation()
        }


    },
    created(){
        if (!this.project.menu) this.project.menu = []
        this.project.menu.forEach((item,idx) => {
            if (!item.id) item.id = Date.now() + '-' + idx
            if (item.children && item.children.length){
                item.children.forEach((child,childI) => {
                    if (!child.id) child.id = Date.now() + '-' + idx + '__' + childI
                })
            }
        })
        let visible = JSON.parse(JSON.stringify(this.project.menu))
        this.visible = visible.map(x => {
            x.visible = false
            if (x.children && x.children.length){
                x.children = x.children.map(y => {
                    y.visible = false
                })
            }
            return x
        })
        console.log('THIS PROJECT MENU', this.project.menu)
    }
}
</script>
<style lang="scss">
    .nav-menu-items-wrapper {
        > .nav-menu-items:last-child{
            opacity:0.3;
            .ant-collapse-item{
                border:0;
            }
        }
    }
    .nav-menu-items .ant-collapse-content-box{
        padding:10px 0 10px 10px;
    }
</style>
