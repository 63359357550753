<template>
  <div class="bg-white px-3 py-3">
      <p>Page Header Code Injection</p>
      <small>Inject custom code or scripts to enhance specific parts of your page. <br/>(This code will be injected inside the &lt;head&gt; tag)</small>
      <a-textarea v-model="project.includes.head" placeholder="Add Code..." />
        
  </div>
</template>

<script>
export default {
  props:['project']
}
</script>

<style>

</style>