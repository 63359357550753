<template>
    <div class="manage-section h-full dF fC">
        <a-modal  :visible="modal.show" @ok="submitModal" @cancel="cancelModal">
            <a-form-model ref="newPage" :model="newPage" v-if="modal.type === 'newPage' ">
                <a-form-model-item label="Enter page name" required prop="name">
                   <a-input v-model="newPage.name" />
                </a-form-model-item>
                <a-form-model-item label="Page to copy Header/Footer from" help="From what page would you like to copy the header & footer?"  required prop="headerPage">
                   <a-select :options="pagesOptionsId" v-model="newPage.headerPage" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <div class="dF aC px-3 py-4" style="gap:1ch;" :class="editSectionObj.show && editSectionObj.item? 'bg-primary text-white' : ''">
            <template v-if="editSectionObj.show && editSectionObj.item">
                <!-- <i class="fa fa-times hover:text-primary" style="cursor:pointer" @click="editSectionObj.show = false"></i> -->
                <a-icon type="arrow-left" class="cursor-pointer" @click="$store.commit('SET_PROP', {where:['builder','currentSection'],what:''})" />
                <h6 class="mb-0" :class="editSectionObj.show && editSectionObj.item? 'text-white' : ''" >{{editSectionObj.item.name}}</h6>
            </template>

            <h6 v-else class="mb-0" style="font-size: 1.15em;">
                Pages
            </h6>

        </div>


            <div key="viewSections">

                <div class="list-group mb-3" :key="newTime">

                    <PageSettings v-model="pageSettingItem" @close="settingPageId = ''" v-if="settingPageId"/>

                    <draggable :list="sections" handle=".handle" :delay-on-touch-only="true" :delay="100">
                        <template v-for="item in project.pages">
							<template v-if="instance.productType == 'highrise'">
								<template v-if="project.type == 'static'">
									<template v-if="item.id && !deletedPages.includes(item.id) && !item.slug.includes('modeldetails') && !item.slug.includes('login') && !item.slug.includes('blogpost')">
										<PageCard :edited="pagesChanged.includes(item.id)" :project="project" @actioned="pageActioned" @set="setHome(item.id)" @rename="renameSection = item.id" :rename-section="renameSection===item.id" @edit="editSection(item)" :item="item"   :key="item.id" />
									</template>
								</template>
								<template v-else>
									<template v-if="item.id && !deletedPages.includes(item.id) && !item.slug.includes('modeldetails')">
										<PageCard :edited="pagesChanged.includes(item.id)" :project="project" @actioned="pageActioned" @set="setHome(item.id)" @rename="renameSection = item.id" :rename-section="renameSection===item.id" @edit="editSection(item)" :item="item"   :key="item.id" />
									</template>
								</template>
							</template>
							<template v-else>
								<template v-if="project.type == 'static'">
									<template v-if="item.id && !deletedPages.includes(item.id) && !item.slug.includes('modeldetails') && !item.slug.includes('login') && !item.slug.includes('blogpost')">
										<PageCard :edited="pagesChanged.includes(item.id)" :project="project" @actioned="pageActioned" @set="setHome(item.id)" @rename="renameSection = item.id" :rename-section="renameSection===item.id" @edit="editSection(item)" :item="item"   :key="item.id" />
									</template>
								</template>
								<template v-else>
									<template v-if="item.id && !deletedPages.includes(item.id)">
										<PageCard :edited="pagesChanged.includes(item.id)" :project="project" @actioned="pageActioned" @set="setHome(item.id)" @rename="renameSection = item.id" :rename-section="renameSection===item.id" @edit="editSection(item)" :item="item"   :key="item.id" />
									</template>
								</template>
							</template>
                        </template>
                    </draggable>

                    <div @click="addPage" v-if="project.type == 'microsite' || project.type == 'static'" class="px-4 section-card-component dF aC jC text-primary cursor-pointer section-add-card">
                        <a-icon type="file-add" />
                        <p class="mb-0 py-3 cursor-pointer text-primary hover:text-primary">Add Page</p>
                    </div>

                </div>

            </div>
    </div>
</template>

<script>
import {setProp} from 'bh-mod'
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import ImageBoxAlt from 'bh-mod/components/common/ImageBoxAlt'
import ImageBoxSelectorGroup from 'bh-mod/components/common/ImageBoxSelectorGroup'
import PageCard from '@/components/site/PageCard'
import PageSettings from '@/components/site/PageSettings'
import ColorPicker from '@/components/site/ColorPicker'
import SectionModal from '@/components/site/SectionModal'
import ColorDialog from '@/components/site/ColorDialog'
import EditSection from '@/components/site/EditSection'
import draggable from 'vuedraggable'
import slugify from 'slugify'

function modify(obj, newObj) {

  Object.keys(obj).forEach(function(key) {
    delete obj[key];
  });

  Object.keys(newObj).forEach(function(key) {
    obj[key] = newObj[key];
  });

}

export default {
    provide () {
        return { makeSlug:this.makeNameSlug }
    },
    components:{
        ImageBoxSelector,ColorDialog,ColorPicker,draggable,ImageBoxSelectorGroup,ImageBoxAlt,PageCard,SectionModal,EditSection,PageSettings,
    },
    inject:['save','load','updateFrame','pagesChanged','addPageToProject'],
    props:['project','event','page'],
    data:()=>({
        newTime:Date.now(),
        newPage:{
            name:'',
            headerPage:'',
        },
        deletedPages:[],
        settingPageId:'',
        modal:{
            show:false,
            type:'',
            data:{}

        },
        editSectionObj:{
            show:false,
            item:{}
        },
        saveSectionName:'',
        renameSection:'',

    }),
    watch:{
        'project.pages':{
            deep:true,
            handler(val){
                console.log('PAGES CHANGEDDDDD DDD*D*DD**D*D*D')
            }
        },
        event(val){

            if (typeof val === 'object'){

                if (val.action === 'sectionEdit'){

                    let id = val.id.replace('section-id-','')
                    let item = this.page.sections.find(x => x.id === id)
                    if (val.classList.includes('section-header') || val.classList.includes('section-footer')){
                        if (this.page.header.id === id)item = this.page.header
                        else item = this.page.footer
                        item.tag = this.page.header.id === item.id ? 'header' : 'footer'
                    }
                    console.log('ABOUT TO EDIT', item)
                    if (item){
                        this.editSection(item)
                    }

                }

            }
        },
        'editSectionObj.show'(val){
            if (!val){
                document.querySelector('iframe').contentWindow.postMessage({action:'unSelect'},this.siteURL)
            }
        },
        selectedID(val){
            console.log('SELECTED ID CHANGEDDDDDDD', val ? val : null)
            if (val){

                let item = this.sections.find(x => x.id === val)

                if (!item){
                    if (this.getHeaders){
                        item = this.getHeaders.find(x => x.id === val)
                    } else if (this.getFooters){
                        item = this.getFooters.find(x => x.id === val)
                    }
                }

                if (!item) {

                    return this.editSectionObj.show = false
                }

                this.editSectionObj.show = true
                this.editSectionObj.item= item

                document.querySelector('iframe').contentWindow.postMessage({action:'selectedSection',id:item.id},this.siteURL)

            } else {
                this.editSectionObj.show = false
            }
        }
    },
    computed:{
        pageSettingItem:{
            get(){
                return this.project.pages && this.project.pages.find(x => x.id === this.settingPageId) || {}
            },
            set(val){
                let index = this.project.pages && this.project.pages.findIndex(x => x.id === val.id)
                setProp(this,['project','pages',index], val)
            }
        },
        selectedID(){
            return this.$store.state.appData.builder.currentSection
        },
        getHeaders(){
            if (this.page.header && this.page.header.id) return [{...this.page.header,tag:'Header'}]
            if (this.project.headers && this.project.headers.length) return this.project.headers.map(x => ({...x,tag:'Header'}))
            return false
        },
        getFooters(){
            if (this.page.footer && this.page.footer.id) return [{...this.page.footer,tag:'Footer'}]
            if (this.project.footers && this.project.footers.length) return this.project.footers.map(x => ({...x,tag:'Footer'}))
            return false
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        siteURL(){
            return this.$store.state.appData.siteURL
        },
        fontList(){
            return Object.values(this.project.fonts)
                .map( ({name,fontText}) => ({
                    label:name,
                    value:fontText
                }))
        },
        pages(){
            return this.project.pages || {}
        },
        sections(){
            return this.page.sections || []
        },
        pagesOptionsId(){
            return this.$store.getters.pagesOptionsId
        },
		instance(){
			return this.$store.state.instance
		}
    },
    methods:{
        cancelModal(){
            this.modal = {
                show:false,
                type:'',
                data:{},
            }
        },
        makeNewPage({name,headerPage}){
            let foundPage = this.pages.find(x => x && x.id === headerPage)
            let {id,_id,...page} = foundPage || {};
            let newPage = JSON.parse(JSON.stringify(page))
            console.log('NEW PAGE name', name,headerPage)
            let {slug} = this.makeNameSlug(name)

            newPage.name = name
            newPage.slug = slug
            newPage.sections = []
            newPage.isIndex = false
            newPage.default = false
            if (newPage.header) delete newPage.header.id,delete newPage.header._id,newPage.header.id = 'add_' + Date.now()
            if (newPage.footer) delete newPage.footer.id,delete newPage.footer._id,newPage.footer.id = 'add_' + Date.now()


            return newPage
        },
        resetModal(){
            this.modal.show = false
            this.modal.type = ''
        },
        async createAdd(page){
            this.addPageToProject(page).then( () => {
                this.resetModal()
            })
            // this.project.pages.push(page)
            // this.$store.commit('LOAD')
            // await this.save()
            // await this.load()
            // this.$store.commit('LOAD',false)
        },
        submitModal(){
            this.$refs.newPage.validate( async result => {
                let newPage = this.makeNewPage(this.newPage)
                this.createAdd(newPage)
            })
        },
        pageActioned({key,page}){
            let self = this
            console.log('page name', page.name)


            if (key === 'delete') {
                this.$confirm({
                    title: "Are you sure?",
                    content: h => <div>This will <strong>delete the page ({page.name})</strong>. This cannot be undone!</div>,
                    okText: 'DELETE',
                    okType: 'danger',
                    cancelText: 'CANCEL',
                    centered: true,

                    onOk:() => {
                        self.deletePage(page.id)
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            } else if (key === "setIndex" ){

                if (page.isIndex){
                    return this.$message.info('This page is already set as Home')
                }

                this.$confirm({
                    title: "Set as Home?",
                    content: h => <div>This will make this page as the home page on your site?</div>,
                    okText: 'OKAY',
                    okType: 'primary',
                    cancelText: 'CANCEL',
                    centered: true,
                    onOk:() => {
                        self.setHome(page.id)
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            } else if (key === 'edit'){
                this.$store.commit('SET_PROP', {where:['builder','currPageId'],what:page.id})
                this.$emit('sections')
            } else if (key === 'duplicate'){
                 this.$confirm({
                    title: "Duplicating Page",
                    content: h => <div>Are you sure ? This will save your current progress so far. Do you wish to continue?</div>,
                    okText: 'YES',
                    okType: 'primary',
                    cancelText: 'NO',
                    centered: true,
                    onOk:() => {
                        self.dupPage(page)
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })

            } else if (key === 'settings'){
                this.settingPageId = page.id
            } else if (key === 'toggleHeaderFooter'){
                page.isHeader = !page.isHeader
                if (page.id === this.page.id) this.updateFrame()
            }
        },
        makeNameSlug(currentName,id = 'xxx'){

            let newName = currentName.split(' ').filter(x => x).join(' ')
            let testName = newName

            let pagesToCheck = this.project.pages.filter(x => x.id !== id)
            if (!pagesToCheck.length) return newName

            let addon = 1
            while (pagesToCheck.find(x => x.name.trim().toLowerCase() == testName.toLowerCase().trim())){
                addon++
                testName = `${newName} ${addon}`
            }

            newName = testName
            var cleanName = newName.split(' ').filter(x => x).map(x => x.replace(/[^0-9A-Z]+/gi,"") ).join(' ')
            console.log(`${newName} => ${cleanName}`)
            let newSlug = cleanName.toLowerCase().replace(/ /g,'-')
            let testSlug = newSlug
            let takenSlugs = ['index','modeldetails','blogpost','login']

            addon=0
            while (pagesToCheck.find(x => x.slug.trim().toLowerCase() == testSlug.toLowerCase().trim() || takenSlugs.includes(testSlug) )){
                addon++
                testSlug = `${newSlug}-${addon}`
                console.log('trying another name', newName)
            }

            return {name:newName,slug:newSlug}

        },
        async dupPage(page){

            let copiedPage = JSON.parse(JSON.stringify(page))
            copiedPage.isIndex = false
            copiedPage.name += ' Copy'
            let newNames = this.makeNameSlug(copiedPage.name)
            copiedPage.name = newNames.name
            copiedPage.slug = newNames.slug
            delete copiedPage.id
            delete copiedPage._id

            if (copiedPage.header) copiedPage.header.id = `add_${copiedPage.header.id}-h`, delete copiedPage.header._id
            if (copiedPage.footer) copiedPage.footer.id = `add_${copiedPage.footer.id}-f`, delete copiedPage.footer._id
            if (copiedPage.sections) copiedPage.sections = copiedPage.sections.map( ({id,_id,...sec},idx) => ({...sec,id:'add_'+Date.now() + idx }) )

            // this.project.pages.push(copiedPage)
            return this.createAdd(copiedPage)
            await this.save()
            await this.load()
        },
        deletePage(id){

            this.$store.commit('LOAD',true)

            this.$api.delete(`/pages/:instance/${id}`).then( () => {
                this.deletedPages.push(id)
            }).catch( () => {
                this.$message.error('There was a problem deleting. Please try refreshing your browser and try again.')
            }).finally ( () => {
                this.$store.commit('LOAD',false)
            })
        },
        setHome(id){
            console.log('SETTING HOME PAGE TO => ', id)
            this.project.pages.forEach(page => {
                console.log('CURR PAGE', page.id)
                if (page.id === id) page.isIndex = true
                else page.isIndex = false
            })

        },
        addPage(){
            this.modal.show = true
            this.modal.type = 'newPage'
            this.newPage = {
                name:'',
                headerPage:'',
            }
        }


    }
}
</script>

<style lang="scss">
    .slide-fade-enter-active {
        transition: all .25s ease;
    }
    .slide-fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }
    .section-editor-accordion-wrapper{
        +.section-editor-accordion-wrapper{
            border-top:1px solid var(--light-purple);
        }
    }
    .section-add-card{
        gap: 1ch;
        border-top: 0px;
        margin: 5px 5px;
        background: rgb(255, 255, 255);
        border-radius: 4px;
        border: 1px dashed currentColor;
        transition:background-color .3s ease-out;
        &:hover{
            background:#efebe6;
        }
    }
</style>
