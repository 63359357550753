<template>
    <div class="manage-section h-full dF fC">
        <bh-loading :show="loading" />
        <a-modal :title="'Rename Blog'" :visible="renameModal.show" @cancel="closeModal" @ok="handleOk" >
            <a-form-model :rules="rules" :model="renameModal" ref="renameModal">
                <a-form-model-item v-if="renameModal.show" label="Blog Title" prop="title">
                    <a-input v-model="renameModal.title" :placeholder="'Blog Title'" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <BlogDrawer :bPage="blogPage" :bPost="blogPost" :loading="loading2" @saveBlog="saveBlog" @saveCloseBlog="saveCloseBlog" @close="onClose" />
        <div class="dF aC px-3 py-4" style="gap:1ch;">
            <h6 class="mb-0" style="font-size: 1.15em;">
                Blog Posts
            </h6>
        </div>
        <div class="list-group mb-3">
            <BlogCard @actionclicked="blogActioned" :item="item" v-for="(item,itemKey) in blogs" :key="itemKey" @click.native="edit(itemKey)" />
            <div @click="addBlog" class="px-4 dF aC jC text-primary cursor-pointer section-add-card">
                <a-icon type="file-add" />
                <p class="mb-0 py-3 cursor-pointer text-primary hover:text-primary">Add Blog</p>
            </div>
        </div>
    </div>
</template>

<script>
import BlogCard from '@/components/site/BlogCard'
import bhLoading from 'bh-mod/components/common/Loading'
import BlogDrawer from '@/components/site/BlogDrawer'
import moment from 'moment'

export default {
    props:['project'],
    components:{
        BlogCard,
        bhLoading,
        BlogDrawer,
        ImageBoxSelector: () => import('bh-mod/components/common/ImageBoxSelector'),
    },
    data:()=>({
        loading:false,
        loading2:false,
        setting:'',
        blogPage:{
            show:false,
            type:''
        },
        blogPost:{},
        blogs:[],
        renameModal:{
            show:false,
            title:'',
            blog:{}
        },
        rules:{
            title: [
                { required:true, message: 'Please enter a blog name', trigger: 'blur' },
            ],
        },
    }),
    computed:{

    },
    methods:{
        moment,
        closeModal(){
            console.log('CLOSE RENAME MODAL')
            this.renameModal = {
                show:false,
                title:'',
                blog:{}
            }
        },
        handleOk(){
            console.log('OK RENAME MODAL')
            this.$refs.renameModal.validate( res => {
                console.log('RES', res)
                if (res){
                    console.log('SAVING BLOG')
                    let sendObj = JSON.parse(JSON.stringify(this.renameModal.blog))
                    sendObj.title = this.renameModal.title
                    console.log('SEND OBJ', sendObj)
                    this.closeModal()
                    this.loading2 = true
                    this.$api.put(`/blog-posts/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                        console.log('DATA COMING BACK', data)
                        let index = this.blogs.findIndex(x => x.id == data.id)
                        console.log('THIS BLOGS AND INDEX', this.blogs, index)
                        this.blogs[index] = data
                        this.loading2 = false
						this.$store.commit('UPDATE_FRAME')
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            })
        },
        textChanged(e){
            this.blogPost.content = e
        },
        edit(val){

        },
        addBlog(){
            this.blogPage.show = true
            this.blogPage.type = 'newBlog'
            this.blogPost = {
                categories:[],
                status:'draft',
                title:'',
                image:'',
                content:'',
                summary:'',
                seo:{
                    title:'',
                    description:''
                },
                date:this.moment(Date.now()),
                slug:''
            }
        },
        blogActioned(e){
            if (e.key == 'edit'){
                console.log('EDIT CLICKED', e.blog)
                this.blogPage.show = true
                this.blogPage.type = 'editBlog'
                this.blogPost = JSON.parse(JSON.stringify(e.blog))
                if (this.blogPost.hasOwnProperty("date")){
                    console.log('HAS DATE', this.blogPost.date)
                    this.blogPost.date = this.moment(parseInt(this.blogPost.date))
                } else {
                    console.log('NO DATE')
                    this.blogPost.date = this.moment(Date.now())
                }
            } else if (e.key == 'rename'){
                console.log('RENAME CLICKED', e.blog)
                let blog = JSON.parse(JSON.stringify(e.blog))
                this.renameModal.show = true
                this.renameModal.blog = blog
                this.renameModal.title = blog.title
            } else if (e.key == 'duplicate'){
                console.log('DUPLICATE CLICKED', e.blog)
                let newBlog = JSON.parse(JSON.stringify(e.blog))
                newBlog.date = this.moment(newBlog.date)
                delete newBlog.id
                delete newBlog._id
                newBlog.title = newBlog.title + ' ' + '(copy)'
                delete newBlog.createdAt
                delete newBlog.updatedAt
                newBlog.slug = this.makeNameSlug(newBlog.title)
                console.log('SENDING NEW BLOGGG', newBlog)
                this.loading = true
                this.$api.post(`/blog-posts/:instance`, newBlog).then( ({data}) => {
                    console.log('DATA COMING BACK', data)
                    this.blogs.push(data)
                    this.loading = false
					this.$store.commit('UPDATE_FRAME')
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            } else {
                console.log('DELETE CLICKED', e.blog)
                let self = this
                this.$confirm({
                    title: "Delete Blog",
                    content: h => <div>Do you want to delete this Blog?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        console.log('Deleted Blog', e.blog)
                        self.loading = true;
                        self.$api.delete(`/blog-posts/:instance/${e.blog.id}`).then( ({data}) => {
                            console.log('DELETED BLOG', data)
                            let index = self.blogs.findIndex(x => x.id == data.id)
                            self.blogs.splice(index,1)
                            self.loading = false;
							this.$store.commit('UPDATE_FRAME')
                        }).catch( () => {
                            self.$message.error('There was a problem deleting. Please try again')
                        })
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            }
        },
        saveBlog(obj){
            console.log('SAVE BLOG', obj)
            let sendObj = JSON.parse(JSON.stringify(obj))
            sendObj.slug = this.makeNameSlug(sendObj.title, sendObj.id)
            sendObj.date = parseInt(this.moment(sendObj.date).format('X'))*1000
            if (this.blogPage.type == 'newBlog'){
                console.log('CREATING NEW BLOG...', sendObj)
                this.loading2 = true
                this.$api.post(`/blog-posts/:instance`, sendObj).then( ({data}) => {
                    console.log('DATA COMING BACK', data)
                    this.blogPage.type = 'editBlog'
                    console.log('THIS BLOGPOST', this.blogPage)
                    this.blogs.push(data)
                    this.blogPost = data
                    if (this.blogPost.hasOwnProperty("date")){
                        console.log('HAS DATE', this.blogPost.date)
                        this.blogPost.date = this.moment(parseInt(this.blogPost.date))
                    } else {
                        console.log('NO DATE')
                        this.blogPost.date = this.moment(Date.now())
                    }
                    this.loading2 = false
                    this.$message.success('Blog Saved!')
					this.$store.commit('UPDATE_FRAME')
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            } else {
                console.log('UPDATING EXISTING BLOG...', sendObj)
                this.loading2 = true
                this.$api.put(`/blog-posts/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                    console.log('DATA COMING BACK', data)
                    let index = this.blogs.findIndex(x => x.id == data.id)
                    console.log('THIS BLOGS AND INDEX', this.blogs, index)
                    this.blogs[index] = data
                    this.loading2 = false
                    this.$message.success('Blog Saved!')
					this.$store.commit('UPDATE_FRAME')
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
        },
        saveCloseBlog(obj){
            console.log('SAVE AND CLOSE BLOG', obj)
            let sendObj = JSON.parse(JSON.stringify(obj))
            sendObj.slug = this.makeNameSlug(sendObj.title, sendObj.id)
            sendObj.date = parseInt(this.moment(sendObj.date).format('X'))*1000
            if (this.blogPage.type == 'newBlog'){
                console.log('CREATING NEW BLOG...', sendObj)
                this.loading2 = true
                this.$api.post(`/blog-posts/:instance`, sendObj).then( ({data}) => {
                    console.log('DATA COMING BACK', data)
                    this.blogs.push(data)
                    this.onClose()
                    this.loading2 = false
                    this.$message.success('Blog Saved!')
					this.$store.commit('UPDATE_FRAME')
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            } else {
                console.log('UPDATING EXISTING BLOG...', sendObj)
                this.loading2 = true
                this.$api.put(`/blog-posts/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                    console.log('DATA COMING BACK', data)
                    let index = this.blogs.findIndex(x => x.id == data.id)
                    console.log('THIS BLOGS AND INDEX', this.blogs, index)
                    this.blogs[index] = data
                    this.loading2 = false
                    this.$message.success('Blog Saved!')
                    this.onClose()
					this.$store.commit('UPDATE_FRAME')
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
        },
        makeNameSlug(currentName,id = 'xxx'){

            let newName = currentName.split(' ').filter(x => x).join(' ')
            let testName = newName

            let pagesToCheck = this.blogs.filter(x => x.id !== id)
            console.log('PAGES TO CHECK', pagesToCheck)
            // if (!pagesToCheck.length){
            //     console.log('newName', newName)
            //     return newName
            // }

            let addon = 1
            while (pagesToCheck.find(x => x.title.trim().toLowerCase() == testName.toLowerCase().trim())){
                addon++
                testName = `${newName} ${addon}`
            }

            newName = testName
            var cleanName = newName.split(' ').filter(x => x).map(x => x.replace(/[^0-9A-Z]+/gi,"") ).join(' ')
            console.log(`${newName} => ${cleanName}`)
            let newSlug = cleanName.toLowerCase().replace(/ /g,'-')
            let testSlug = newSlug
            let takenSlugs = ['index','modeldetails','blogpost']

            addon=0
            while (pagesToCheck.find(x => x.slug.trim().toLowerCase() == testSlug.toLowerCase().trim() || takenSlugs.includes(testSlug) )){
                addon++
                testSlug = `${newSlug}-${addon}`
                console.log('trying another name', newName)
            }

            console.log('newSlug', newSlug)
            return newSlug

        },
        onClose(){
            console.log('ONCLOSE CLICKED')
            this.blogPage = {
                show:false,
                type:''
            }
        }
    },
    created(){
        this.loading = true
        this.$api.get(`/blog-posts/:instance`).then( ({data}) => {
            console.log('BLOG POSTS COMING BACK FROM GET REQUEST', data)
            this.blogs = data
            this.blogs.forEach(x => {
                if (!x.hasOwnProperty('image')){
                    x.image = ''
                }
            })
            this.loading = false
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
    }
}
</script>
<style lang="scss" scoped>
    .popoverContent{
        height: 35px;
        // width: 100px;
        line-height: 35px;
        padding-left: 10px;
        padding-right:10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .back-button:hover {
        background-color: var(--off-white-light);;
    }
    @media screen and (min-width: 768px){
        .blog-page-editor {
            display: flex;
        }
        .blog-editor-left {
            margin-right: 2em;
        }
        .blog-card:not(:first-child) {
            margin-top: 2em;
        }
    }
    @media screen and (max-width: 768px){
        .blog-card {
            margin-top: 2em;
        }
    }
</style>

<style lang="scss">
    .slide-fade-enter-active {
        transition: all .25s ease;
    }
    .slide-fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }
    .section-editor-accordion-wrapper{
        +.section-editor-accordion-wrapper{
            border-top:1px solid var(--light-purple);
        }
    }
    .section-add-card{
        gap: 1ch;
        border-top: 0px;
        margin: 5px 5px;
        background: rgb(255, 255, 255);
        border-radius: 4px;
        border: 1px dashed currentColor;
        transition:background-color .3s ease-out;
        &:hover{
            background:#efebe6;
        }
    }
    .blogDrawer .ant-drawer-wrapper-body {
        background-color: rgb(241, 241, 241);
    }
</style>
