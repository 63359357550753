<template >
    <div class=" bg-white px-3 section-card-component dF jSB aC" :class="item.id === currPageId ? 'page-currently-editing' : '' ">

        <!-- <span class="section-card-tag" v-if="item.tag" v-text="item.tag" /> -->

        <div @click="actioned({key:'edit'})" class="dF aC py-3 f1 cursor-pointer hover:text-primary relative page-name-holder" style="gap:1ch;" >
            <!-- <a-badge :dot="item.id === currPageId"> -->
                <a-tooltip overlayClassName="change-tooltip-color" :title="item.id === currPageId ? `Currently Editing` : '' " :mouseEnterDelay="2">
                    <a-icon :type="item.isIndex? 'home' : 'file'" />
                </a-tooltip>
            <!-- </a-badge> -->
            <a-input-search ref="rename" v-model="rename" v-if="renameBool" placeholder="input search text" size="large" @search="renamePage" @keyup.esc="renameBool = false">
                <a-button slot="enterButton" icon="check" />

            </a-input-search>
            <p v-else class="mb-0 " :style="`width:calc(${width}px - 2ch);text-overflow:ellipsis;overflow:hidden;white-space: nowrap`">
                <a-tooltip overlayClassName="change-tooltip-color" :title="edited ? 'Page has been edited' : ''">
                    <span>{{item.tag ? item.tag : item.name}}</span> <a-badge dot v-if="edited && false" />
                </a-tooltip>
                <a-tooltip overlayClassName="change-tooltip-color" title='This page is locked because...'>
                    <a-icon style="margin-left:1ch;" class="text-med-gray" type="lock" v-if="locked" />
                </a-tooltip>
            </p>


        </div>

        <MoreActions :items="actions" @action="actioned" />

    </div>
</template>

<script>
import MoreActions from 'bh-mod/components/common/MoreActions'
export default {
    props:['item','renameSection','edited','project'],
    components:{MoreActions},
    data:() => ({
        renameBool:false,
        width:200,
        renameBool:false,
        rename:'',
    }),
    watch:{
        renameBool(val){
            // this.$nextTick( () => {
                setTimeout(() => {
                    if (this.$refs.rename.$refs.input){
                        this.$refs.rename.$refs.input.focus();
                    }
                }, 200);
            // })
        },
        renameSection(val){
            if (val !== this.renameBool) this.renameBool = val
        },
        renameBool(val){
            this.$emit('renameClose')
        },
    },
    computed:{
        locked(){
            let result = false
            if (this.item.slug === 'modeldetails' || this.item.slug === 'blogdetails' || this.item.slug === 'blogpost' || this.item.slug === 'thankyou' || this.item.slug == 'login') result = true
            return result
        },
        currPageId(){
            return this.$store.state.appData.builder.currPageId
        },
        actions(){
            let result = [
                {
                    label:'Edit Page',
                    value:'edit',
                    icon:'fe-edit',
                },
                {
                    label:'Set as Home',
                    value:'setIndex',
                    icon:'fe-home',
                    hidden:this.locked

                },
                {
                    label:'Page settings',
                    value:'settings',
                    icon:'setting',
                },
                {
                    label:'Duplicate',
                    value:'duplicate',
                    icon:'fe-copy',
                    hidden:this.locked
                },
                {
                    label:'Delete',
                    value:'delete',
                    icon:'fe-trash',
                    hidden:this.locked
                },
            ]
            if (this.project.type == 'landing'){
                result = [
                    {
                        label:'Edit Page',
                        value:'edit',
                        icon:'fe-edit',
                    },
                    {
                        label:'Page settings',
                        value:'settings',
                        icon:'setting',
                    }
                ]
            }
            return result.filter(item => {
                return !item.hidden
            })
        },
        image(){
            if (this.item.tag) return require(`@/assets/icons/${this.item.tag.toLowerCase()}.svg`)
            if (!this.item.icon) return require(`@/assets/icons/content.svg`)
            if (this.item.icon.indexOf('http') === 0 && this.item.icon.includes('://')){
                return this.item.icon
            }
            return require(`@/assets/icons/${this.item.icon || 'content'}.svg`)
        },
        siteURL(){
            return this.$store.state.appData.siteURL
        },
    },
    methods:{
        renamePage(){
            this.item.name = this.rename
            this.rename = ''
            this.renameBool = false
        },
        actioned(e){
            if (e.key === 'rename'){
                this.renameBool = true
            }else {

                this.$emit('actioned',{key:e.key,page:this.item})
            }

        },
        test(){

        },
    },
    created(){
        this.rename = this.item.name
    },
    mounted(){
        this.width = this.$el.querySelector('.page-name-holder').clientWidth - 20
    }
}
</script>

<style lang="scss" scoped>
    .page-currently-editing{
        border-left:2px solid var(--primary) !important;
        background-color:#fcf8f4 !important;
    }
    .section-card-component{
        border:1px solid var(--light-purple);
        border-left:0;
        border-right:0;
        position: relative;
        + .section-card-component{
            border-top:0;
        }
    }
    .handle{
        cursor:-moz-grab;
        cursor:-webkit-grab;
    }
    .section-card-tag{
        position: absolute;
        top:3px;
        left:20px;
        font-size:12px;
        margin-left:calc(20px + 1ch);
        color:var(--med-gray);
    }
</style>
