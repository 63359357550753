<template>
    <div>
        <!-- <h4 class=mb-3>Fonts</h4> -->
        <div class="px-4 bg-white py-4">

            <div style="gap:2ch;">
                <div class="text-md mb-2">Primary heading</div>
                <div v-if="$store.state.appData.fontList.length">

                    <font-picker api-key="asdf" :options="{name:'primary', variants:['regular', 'italic', '700', '700italic']}" :active-font="fonts.primary" @change="fontSelected($event,'primary')"></font-picker>

                    <div class="dF aC text-secondary mt-1" style="gap:1ch;">
                        Bold <a-switch size="small" v-model="bold.primary" />
                        &nbsp; Italic <a-switch size="small"  v-model="italic.primary" />
                    </div>
                </div>
                <div v-else>
                    <a-icon type="loading" size="large" />
                </div>
            </div>

            <div class="aC" style="gap:2ch;">
                <div class="text-md mb-2">Secondary heading</div>
                <div v-if="$store.state.appData.fontList.length">
                    <font-picker api-key="asdf" :options="{name:'secondary', variants:['regular', 'italic', '700', '700italic']}" :active-font="fonts.secondary" @change="fontSelected($event,'secondary')"></font-picker>
                    <div class="dF aC text-secondary mt-1" style="gap:1ch;">
                        Bold <a-switch size="small" v-model="bold.secondary" />
                        &nbsp; Italic <a-switch size="small"  v-model="italic.secondary" />
                    </div>
                </div>
            </div>

            <div class="aC" style="gap:2ch;">
                <div class="text-md mb-2">Body Text</div>
                <div v-if="$store.state.appData.fontList.length">
                    <font-picker api-key="asdf" :options="{name:'body', variants:['regular', 'italic', '700', '700italic']}" :active-font="fonts.body" @change="fontSelected($event,'body')"></font-picker>
                    <div class="dF aC text-secondary mt-1" style="gap:1ch;">
                        Bold <a-switch size="small" v-model="bold.body" />
                        &nbsp; Italic <a-switch size="small"  v-model="italic.body" />
                    </div>
                </div>
            </div>

            <div class="aC" style="gap:2ch;">
                <div class="text-md mb-2">Accent Text</div>
                <div v-if="$store.state.appData.fontList.length">
                    <font-picker api-key="asdf" :options="{name:'accent', variants:['regular', 'italic', '700', '700italic']}" :active-font="fonts.accent" @change="fontSelected($event,'accent')"></font-picker>
                    <div class="dF aC text-secondary mt-1" style="gap:1ch;">
                        Bold <a-switch size="small" v-model="bold.accent" />
                        &nbsp; Italic <a-switch size="small"  v-model="italic.accent" />
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

    import {getAppIcon,validateEmail,setProp} from 'bh-mod'
    import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
    import FontPicker from '@/components/common/font-selector';
    import axios from 'axios'


    var colorChoices = require('nice-color-palettes');

    export default {
        props:['project'],
        components:{
            ImageBoxSelector,FontPicker,
        },
        data:() => ({
            newTime:Date.now(),
            bold:{
                primary:true,
                secondary:false,
                body:false,
                accent:false
            },
            italic:{
                primary:false,
                secondary:true,
                body:false,
                accent:true
            },
            fonts:{
                primary:'Bitter',
                secondary:'Open Sans',
                body:'Open Sans',
                accent:'Balsamiq Sans'
            }
        }),
        computed:{
            fontList(){
                let fonts = this.$store.state.appData.fontList
                let obj = {}
                fonts.forEach(item => {

                    let boldWeights = []

                    let hasBold = false
                    let boldVariant = 0

                    let italicWeights = []
                    let hasItalics = false
                    let italicVariant = 0

                    let boldItalicWeights = []
                    let hasBoldItalics = false
                    let boldItalicVariant = 0



                    item.weights = item.variants.map( wght => {

                        if (wght === 'regular') return '400'
                        else if (wght === 'italic') return '400i'

                        if (wght.indexOf('italic') > 0) return wght.replace('italic','i')

                    })
                    item.weights.forEach( weight => {

                        let w = parseInt(weight)
                        if (isNaN(w)) return

                        if (weight.includes('i')){
                            hasItalics = true
                            italicWeights.push(w)
                            if (w > 250 && w < 600){
                                hasItalics = true
                                if (w === 400){

                                }

                            } else if (w > 600) {

                            }

                        } else if (w > 650) {

                            if (w == 700) {

                                hasBold = true
                                boldVariant = 700

                            } else if (w == 800) {

                                hasBold = true
                                boldVariant = 800

                            } else if (w == 900) {

                                hasBold = true
                                boldVariant = 900

                            }

                        }

                    })


                    item.category = (!item || item.category === 'handwriting') ? 'cursive' : item.category
                    item.hasBold = hasBold
                    item.boldVariant = boldVariant
                    obj[item.family] = item

                })
				console.log('Computed Font List', obj)
                return obj
            },
            fontObj(){
                if (!Object.keys(this.fontList).length) return {};
				if (!this.fonts) return {};

                let {primary,secondary,body,accent} = this.fonts
                let chosenPrimary = this.fontList[primary]
                let chosenSecondary = this.fontList[secondary]
                let chosenBody = this.fontList[body]
                let chosenAccent = this.fontList[accent]


                let makeFontObj = (f,cat = 'sans-serif',t) => {
                    f = f && f.trim()
                    let bold = this.bold[t]
                    let italic = this.italic[t]
                    let weights = ['400']

                    if (bold && italic) weights = ['700i']
                    else if (bold) weights = ['700']
                    else if (italic) weights = ['400i']

                    return {
                        name:f,
                        google:f && f.replace(/ /g, '+'),
                        fontText:`'${f}', ${cat}`,
                        bold,
                        italic,
                        weights
                    }
                }


				console.log('Font List', this.fontList)
                console.log('primary => ', primary)
                console.log('chosenPrimary => ', this.fontList[primary])

                console.log('secondary => ', secondary)
                console.log('chosenSecondary => ', this.fontList[secondary])


                primary = makeFontObj(primary, (chosenPrimary && chosenPrimary.category),'primary')
                secondary = makeFontObj(secondary, (chosenSecondary && chosenSecondary.category),'secondary')
                body = makeFontObj(body, (chosenBody && chosenBody.category),'body')
                accent = makeFontObj(accent, (chosenAccent && chosenAccent.category),'accent')

                return {
                    primary,
                    secondary,
                    body,
                    accent
                }
            },
            instance(){
                return this.$store.state.instance
            },
        },
        watch:{
            fontObj:{
                deep:true,
                handler(val){
					console.log('FontObj watch ran', val)
                    this.project.fonts = {...val}
                }
            }

        },
        methods:{
            getStyle(t){

                if (!t) return ''
                let style = ''

                if (this.bold[t]) style += 'font-weight:700;'
                if (this.italic[t]) style+= 'font-style:italic'

                return style

            },
            fontSelected(e,f){

                this.fonts[f] = e.family

            },
            generateFont(){

                if (this.fontList && Object.values(this.fontList).length) return

                axios.get('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyC5XCJYbRRAbcAZLdIp7OAY232lc96HiS8').then( ({data}) => {
                    this.$store.commit('SET_PROP', {where:['fontList'],what:data.items})
                })

            },
            next(){

                return this.$emit('done', {
                    fonts:this.fontObj
                })

            },


        },
        created(){
			console.log('FONT SETTING CREATED')
            this.generateFont()
			if (this.project.fonts) {
				this.fonts = {
					primary: this.project.fonts.primary && this.project.fonts.primary.name,
					secondary: this.project.fonts.secondary && this.project.fonts.secondary.name,
					accent: this.project.fonts.accent && this.project.fonts.accent.name,
					body: this.project.fonts.body && this.project.fonts.body.name,
				}

				this.bold.primary = this.project.fonts.primary && this.project.fonts.primary.bold
				this.bold.secondary = this.project.fonts.secondary && this.project.fonts.secondary.bold
				this.bold.accent = this.project.fonts.accent && this.project.fonts.accent.bold
				this.bold.body = this.project.fonts.body && this.project.fonts.body.bold

				this.italic.primary = this.project.fonts.primary && this.project.fonts.primary.italic
				this.italic.secondary = this.project.fonts.secondary && this.project.fonts.secondary.italic
				this.italic.accent = this.project.fonts.accent && this.project.fonts.accent.italic
				this.italic.body = this.project.fonts.body && this.project.fonts.body.italic
			}
        }
    }
</script>

<style lang="scss" >
    .typo-preview{
        padding: 20px;
        border: 1px solid #ccc;
    }
    .colr-grid{
        display:grid;
        grid-template-columns: repeat(1, 1fr);
        gap:1.5ch;
        > div {
            max-width: 300px;
        }
        @media screen and (min-width:1200px){
            gap:3.5ch;
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .title-second-color-picker{
        margin-top:30px;
        margin-bottom:10px;
        @media screen and (min-width:1200px){
            margin-top:70px;
        }
    }
    .scheme-choice{
        display:flex;
        cursor:pointer;
        padding:5px;
        border:1px solid transparent;
        &.selected-color-suggestion{
            border:1px solid var(--secondary);
        }
        span{
            height:15px;
            flex:1;
            background:var(--color-here);
        }
    }

</style>
