<template>
    <div>
        <a-modal :visible="newModal.show" @ok="submitNew" @cancel="newModal.show = false">
            <a-form>
                <a-form-item label="From" required class=mb-1>
                    <a-input v-model="newModal.from" placeholder="'https://domain.com/old-ranked-link' or '/old-ranked-link'" />
                </a-form-item>
                <a-form-item label="To" required class=mb-1>
                    <a-input v-model="newModal.to" placeholder="'https://domain.com/new-link' or '/new-link'" />
                </a-form-item>
                <a-form-item label="Redirect Type" required class=mb-0>
                    <a-select v-model="newModal.type">
                        <a-select-option value="301">301 (Permanent)</a-select-option>
                        <a-select-option value="302">302 (Temporary)</a-select-option>
                        <a-select-option value="canonical">Canonical</a-select-option>
                    </a-select>
                </a-form-item>

            </a-form>
        </a-modal>
        <div class="mb-3 dF ">
            <a-button type="primary" class="editable-add-btn" @click="handleAdd">
                Add Redirect
            </a-button>
        </div>
        <a-table :columns="columns" :data-source="data" bordered>

            <template slot="type" slot-scope="text, record, index">
                <a-tag :color="tagColor[record.type]">
                    {{record.type}}
                </a-tag>
            </template>

            <template
                v-for="col in ['from', 'to']"
                :slot="col"
                slot-scope="text, record, index"
            >
                <div :key="col">
                    {{ text }}
                </div>
            </template>
            <template slot="operation" slot-scope="text, record, index">
                <div class="editable-row-operations">
                    <a-icon type="delete" @click="() => del(record.key)" />
                </div>
            </template>
        </a-table>
    </div>
</template>

<script>
import EditableCell from './EditableCell'
export default {
    components:{
        EditableCell,
    },
    props:{
        value:{
            type:Array,
            default(){
                return []
            }
        }
    },
    watch:{
        data(val){
            this.$emit('input',val)
        },
        'newModal.show'(val){
            if (val){
                this.newModal.from = ''
                this.newModal.to = ''
                this.newModal.type = '301'
            }
        }
    },
    data:() => ({
        tagColor:{
            301:'#52c41a',
            302:'#1890ff',
            canonical:'#f5222d',
        },
        newModal:{
            show:false,
            from:'',
            to:'',
            type:'301',
        },
        data:[],
        editingKey: '',
        cacheData:[],
        dataSource: [],
        columns: [
            {
                title: 'Type',
                dataIndex: 'type',
                width:'10%',
                scopedSlots: { customRender: 'type' },
            },
            {
                title: 'Source (URL)',
                dataIndex: 'from',
                scopedSlots: { customRender: 'from' },
            },
            {
                title: 'Redirect To (URL)',
                dataIndex: 'to',
            },
            {
                title: '',
                width: '10%',
                scopedSlots: { customRender: 'operation' },
            },
        ],
    }),
    
    methods:{
        submitNew(){
            this.data.push({
                from:this.newModal.from,
                to:this.newModal.to,
                type:this.newModal.type,
                key:this.data.length
            })
            this.newModal.show = false
        },
        del(key){
            this.data = this.data.filter(item => item.key !== key)
        },
        handleAdd() {
            this.newModal.show = true
            // this.edititngKey = this.data.length
            // this.data.push({
            //     key: `${this.data.length}`,
            //     from: 'asd',
            //     to: 'asda',
            // });
        },
        handleChange(value, key, column) {
            const newData = [...this.data];
            const target = newData.find(item => key === item.key)[0];
            if (target) {
                target[column] = value;
                this.data = newData;
            }
        },
        edit(key) {
            console.log('HANDLING EDIT', this.data)
            const newData = [...this.data];
            const target = newData.find(item => key === item.key)[0];
            this.editingKey = key;
            if (target) {
                target.editable = true;
                this.data = newData;
            }
        },
        save(key) {
            console.log('HANDLING SAVE', this.data)
            const newData = [...this.data];
            const newCacheData = [...this.cacheData];
            const target = newData.find(item => key === item.key)[0];
            const targetCache = newCacheData.find(item => key === item.key)[0];
            if (target && targetCache) {
                delete target.editable;
                this.data = newData;
                Object.assign(targetCache, target);
                this.cacheData = newCacheData;
            }
            this.editingKey = '';
        },
        cancel(key) {
            const newData = [...this.data];
            const target = newData.filter(item => key === item.key)[0];
            this.editingKey = '';
            if (target) {
                Object.assign(target, this.cacheData.filter(item => key === item.key)[0]);
                delete target.editable;
                this.data = newData;
            }
        },
    },
    created(){
        this.data = this.value
    }
}
</script>

<style scoped>
    .editable-row-operations a {
        margin-right: 8px;
    }
</style>